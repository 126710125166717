import React, { MouseEventHandler, useContext } from 'react';
import clsx from 'clsx';
import { SelectionApiContext, useSelectedNode } from './hooks';
import { CTDatum } from './runTreeTypes';

export type ExtractionNodeProps = {
    node: CTDatum;
    onMouseOver?: MouseEventHandler<SVGElement>;
    isHighlighted?: boolean;
};

const ExtractionNode = ({ node }: ExtractionNodeProps): JSX.Element => {
    const selection = useSelectedNode();
    const isSelected = selection?.selectedNode?.uuid === node.uuid;
    const isHighlighted = selection?.highlightedNodes.includes(node.uuid);

    const { onSelectNode, onHighlightNode, clearHighlight } = useContext(SelectionApiContext);
    const numRows = Math.max(
        ...Object.values(node.body).map((v: string[]) => {
            if (!v.values && Array.isArray(v)) return v.length;
            return v.values?.length || 0;
        }),
    );

    return (
        <div className="context-tree-node-wrapper">
            <div
                className={clsx('context-tree-node-base', 'extraction-node', {
                    'selected-node': isSelected,
                    'highlighted-node': isHighlighted,
                })}
                onClick={(e) => {
                    onSelectNode(node);
                    e.stopPropagation();
                }}
                onMouseEnter={() => {
                    onHighlightNode('uuid', node.uuid);
                }}
                onMouseLeave={() => {
                    clearHighlight(node.uuid);
                }}
            >
                <div className={clsx('context-tree-node', { 'selected-node': isSelected })}>
                    <b className="node-title">Extraction </b>
                    <br />
                    {numRows} rows
                </div>
            </div>
        </div>
    );
};

export default ExtractionNode;
