import React  from 'react';
import { IconButton, Menu, MenuItem, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MoreVert } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
    icon: {
        paddingRight: theme.spacing(1),
    },
    menuButton: {
        padding: 6,
    },
}));

export interface PopupMenuProps {
    items?: Array<{ icon?: JSX.Element; label: string | React.ReactElement; hidden?: boolean; callback: () => void }>;
}

const PopupMenu = (props: PopupMenuProps): JSX.Element => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const numItems = props ? (props.items ? props.items.length : 0) : 0;
    if (!numItems) return <></>;
    return (
        <>
            <IconButton
                className={classes.menuButton}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                size="large">
                <MoreVert />
            </IconButton>
            <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                {props.items
                    ?.filter((item) => !item.hidden)
                    .map((item, idx) => (
                        <MenuItem
                            key={idx}
                            onClick={(event) => {
                                event.stopPropagation();
                                item.callback();
                                handleClose();
                            }}
                        >
                            {item.icon && <span className={classes.icon}>{item.icon}</span>}
                            {item.label}
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
};

export default PopupMenu;
