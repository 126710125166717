import React from 'react';
import { CTDatum } from './runTreeTypes';
import ViewerLabel from './ViewerLabel';
import JsonViewer from './JsonViewer';
import { GeckoContextTree } from '@webacq/wa-shared-definitions';

export type NavigationViewerProps = {
    node: CTDatum;
    onOpenUrl?: (url: string) => void;
};
const NavigationViewer = ({ node, onOpenUrl = () => {} }: NavigationViewerProps): React.ReactElement => {
    const url = (node.body as GeckoContextTree).url || '';
    return (
        <>
            <ViewerLabel name="Navigation" description={<a onClick={() => onOpenUrl(url)}>{url}</a>} />
            <div className="data-viewer-content data-viewer-json">
                <JsonViewer name="contextTree" data={(node.body || {}) as Record<string, any>} />
            </div>
        </>
    );
};

export default NavigationViewer;
