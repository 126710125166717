/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Box, ListItemText, MenuItem, Select, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Edit as PencilIcon } from '@mui/icons-material';
import { CustomAlertOption, maxAlertOptionNameLength } from './utils';
import { AlertOptionType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
    box: {
        // marginRight: theme.spacing(0),
    },
    description: {
        color: theme.palette.grey[300],
    },
}));

interface AlertOptionsPropsType {
    options: AlertOptionType[];
    selectedId?: string;
    onChange: (alertOptionId: string) => void;
}

const AlertOptions = (props: AlertOptionsPropsType): JSX.Element => {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" className={classes.box}>
            <Typography variant="h6" gutterBottom>
                Choose Alert Option
            </Typography>

            <Select
                fullWidth
                labelId="label"
                variant="outlined"
                value={
                    props.options.some((option) => option.id === props.selectedId)
                        ? props.selectedId
                        : CustomAlertOption.id
                }
                onChange={(event) => props.onChange(event.target.value as string)}
            >
                {props.options.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        <ListItemText
                            primary={
                                <Box display="flex">
                                    <Box width={`${maxAlertOptionNameLength(props.options)}ch`}> {option.name}</Box>
                                    <Box className={classes.description}>{option.description}</Box>
                                </Box>
                            }
                        />
                    </MenuItem>
                ))}
                <MenuItem key={CustomAlertOption.id} value={CustomAlertOption.id}>
                    <ListItemText
                        primary={
                            <Box display="flex">
                                <Box width={`${maxAlertOptionNameLength(props.options)}ch`}>
                                    <PencilIcon fontSize="inherit" />
                                    &nbsp;&nbsp;
                                    {CustomAlertOption.name}
                                </Box>
                                <Box className={classes.description}>{CustomAlertOption.description}</Box>
                            </Box>
                        }
                    />
                </MenuItem>
            </Select>
        </Box>
    );
};

export default AlertOptions;
