import React from 'react';
import ReactJson from 'react-json-view';

export type JsonViewerProps = {
    data: Record<string, any>;
    name?: string;
};

const JsonViewer = ({ data, name }: JsonViewerProps): React.ReactElement => {
    return (
        <ReactJson
            style={{ minHeight: '100%', backgroundColor: '#282828' }}
            src={data}
            name={name}
            displayObjectSize={false}
            displayDataTypes={false}
            theme="monokai"
            shouldCollapse={(field) => field.name === 'children' || field.name === 'headers'}
        />
    );
};

export default JsonViewer;
