/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Box, Button, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Add as AddIcon, Save as SaveIcon } from '@mui/icons-material';
import { AlysMetric, AlysOperator } from '@webacq/wa-shared-definitions';
import SingleAlertConfiguration from './SingleAlertConfiguration';
import ConfirmationDialog from './ConfirmationDialog';
import { UserInfoType } from './AlertNotification/UserInput';
import { AlertConfigurationData, AlertConfigurationStateData, pluralSuffix } from './utils';
import { AlertOptionType } from './types';

const useStyles = makeStyles((theme: Theme) => ({
    limitReachedInfo: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
    },
}));

interface AlertConfigurationPropsType {
    maxConfigurations: number;

    alertOptions: AlertOptionType[];

    alertMetrics: AlysMetric[];
    alertConditionOperators: AlysOperator[];

    alertConfigs: AlertConfigurationStateData[];

    onLoadAlertConfig: (alertConfigId: string) => Promise<AlertConfigurationData | undefined>;
    onQueryUsers: (query: string, maxRecords: number) => Promise<{ matches: UserInfoType[]; errormsg?: string }>;
    onUpdated: (alertConfigs: AlertConfigurationStateData[]) => void;
    onSaveRequested?: () => Promise<boolean>;
}

const AlertConfiguration = (props: AlertConfigurationPropsType): React.JSX.Element => {
    const classes = useStyles();

    const [deleteConfirmationDialogArgs, setDeleteConfirmationDialogArgs] = React.useState<{
        alertConfigIdx: number;
    }>();

    const addAlertConfig = () => {
        const newAlertConfigs = props.alertConfigs
            .map((alertConfig) => alertConfig.clone())
            .map((alertConfig) => {
                alertConfig.collapsed = true;
                alertConfig.useTransition = false;

                return alertConfig;
            });

        const newAlertConfig = new AlertConfigurationStateData();
        newAlertConfig.modified = true;
        newAlertConfig.collapsed = false;
        newAlertConfig.useTransition = true;

        newAlertConfigs.push(newAlertConfig);

        props.onUpdated(newAlertConfigs);
    };

    const updateAlertConfig = (idx: number, alertConfig: AlertConfigurationData, modified: boolean) => {
        const newAlertConfigs = props.alertConfigs.map((alertConfig) => alertConfig.clone());
        newAlertConfigs[idx].config = alertConfig;
        newAlertConfigs[idx].modified = modified;
        props.onUpdated(newAlertConfigs);
    };

    const deleteAlertConfig = (idx: number) => {
        const newAlertConfigs = props.alertConfigs.map((alertConfig) => alertConfig.clone());
        newAlertConfigs.splice(idx, 1);
        props.onUpdated(newAlertConfigs);
    };

    const updateCollapsedState = (idx: number, collapsed: boolean) => {
        const newAlertConfigs = props.alertConfigs.map((alertConfig) => alertConfig.clone());
        newAlertConfigs[idx].collapsed = collapsed;
        newAlertConfigs[idx].useTransition = true;
        props.onUpdated(newAlertConfigs);
    };

    React.useEffect(() => {
        if (props.alertConfigs.length === 0) {
            addAlertConfig();
        }
    }, [props.alertConfigs]);

    return (
        <Box display="flex" flexDirection="column" width="100%" height="100%">
            <Box overflow="auto">
                {props.alertConfigs.map((alertConfig, idx) => (
                    <SingleAlertConfiguration
                        key={`alertConfig-${idx}`}
                        cfgIdx={idx}
                        alertOptions={props.alertOptions}
                        alertMetrics={props.alertMetrics}
                        alertConditionOperators={props.alertConditionOperators}
                        alertConfig={alertConfig.config}
                        collapsed={alertConfig.collapsed}
                        useTransition={alertConfig.useTransition}
                        modified={alertConfig.modified}
                        onQueryUsers={props.onQueryUsers}
                        onChange={(alertConfig, modified) => updateAlertConfig(idx, alertConfig, modified)}
                        onCollapsedChanged={(collapsed) => updateCollapsedState(idx, collapsed)}
                        onDelete={() =>
                            setDeleteConfirmationDialogArgs({
                                alertConfigIdx: idx,
                            })
                        }
                        onLoadAlertConfig={props.onLoadAlertConfig}
                    />
                ))}
            </Box>
            <Box>
                <Box display="flex">
                    {props.alertConfigs.length < props.maxConfigurations ? (
                        <Button variant="text" startIcon={<AddIcon />} onClick={() => addAlertConfig()}>
                            Add New Alert Configuration
                        </Button>
                    ) : (
                        <Box width="100%" className={classes.limitReachedInfo}>{`Limitation of ${
                            props.maxConfigurations
                        } alert configuration${pluralSuffix(props.maxConfigurations)} reached`}</Box>
                    )}
                </Box>

                <Box display="flex" justifyContent="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        onClick={async () => {
                            if (props.onSaveRequested) {
                                const saveSuccess = await props.onSaveRequested();
                                if (saveSuccess) {
                                    const newAlertConfigs = props.alertConfigs
                                        .map((alertConfig) => alertConfig.clone())
                                        .map((alertConfig) => {
                                            alertConfig.modified = false;
                                            return alertConfig;
                                        });

                                    props.onUpdated(newAlertConfigs);
                                }
                            }
                        }}
                    >
                        {`Save Alert Configuration${pluralSuffix(props.alertConfigs.length)}`}
                    </Button>
                </Box>
            </Box>

            <ConfirmationDialog
                title="Delete this Alert Configuration?"
                isOpen={!!deleteConfirmationDialogArgs}
                buttons={[
                    {
                        label: 'Delete',
                        onClick: () => {
                            if (deleteConfirmationDialogArgs) {
                                deleteAlertConfig(deleteConfirmationDialogArgs.alertConfigIdx);
                                setDeleteConfirmationDialogArgs(undefined);
                            }
                        },
                    },
                    {
                        label: 'Keep It',
                        onClick: () => setDeleteConfirmationDialogArgs(undefined),
                        focus: true,
                    },
                ]}
                onClose={() => setDeleteConfirmationDialogArgs(undefined)}
            />
        </Box>
    );
};

export default AlertConfiguration;
