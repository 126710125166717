/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlysMetric } from '@webacq/wa-shared-definitions';

const useStyles = makeStyles({
    select: {
        width: '30ch',
    },
});

type AlysMetricId = AlysMetric['id'];

interface MetricSelectorPropsType {
    metrics: AlysMetric[];
    allowEmpty?: boolean;
    selectedMetricId?: AlysMetricId;
    onChange: (metricId: AlysMetricId) => void;
}

const MetricSelector = (props: MetricSelectorPropsType): JSX.Element => {
    const classes = useStyles();

    const defaultValue = props.allowEmpty && !props.selectedMetricId ? '' : props.metrics[0].id;

    return (
        <>
            <FormControl size="small">
                {props.allowEmpty && !props.selectedMetricId && (
                    <InputLabel id="label">&nbsp;&nbsp;&nbsp;&nbsp;Select Metric</InputLabel>
                )}
                <Select
                    className={classes.select}
                    labelId="label"
                    variant="outlined"
                    value={props.selectedMetricId || defaultValue}
                    onChange={(event) => props.onChange(event.target.value as AlysMetricId)}
                >
                    {props.metrics.map((metric) => (
                        <MenuItem key={metric.id} value={metric.id}>
                            {metric.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default MetricSelector;
