import React, { useRef, useContext, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { GeckoContextTree } from '@webacq/wa-shared-definitions';
import { SelectionApiContext, useSelectedNode } from './hooks';
import { ContextTreeMap, CTDatum } from './runTreeTypes';
import NavNode from './NavNode';
import ExtractionNode from './ExtractionNode';
import ChangeDetectionNode from './ChangeDetectionNode';
import DeliveryNode from './DeliveryNode';
export type TreeProps = {
    contextTree: GeckoContextTree;
    contextTreeMap: ContextTreeMap;
    geckoGraph: Record<string, any>;
};

const fillColors = {
    start: '#75eca4',
    navigation: '#75eca4',
    extraction: '#c13cd6',
    delivery: '#f49565',
    changeDetection: '#08f9f9',
    highlighted: '#bff91f',
    selected: '#1663f3',
};

const Timeline = ({ contextTreeMap }: TreeProps): JSX.Element => {
    const selection = useSelectedNode();
    const { onSelectNode, onHighlightNode, clearHighlight } = useContext(SelectionApiContext);

    type SeriesData = Array<{ x: string; y: number[]; fillColor?: string }>;
    const data: SeriesData = [];
    for (const [id, node] of contextTreeMap) {
        if (node.startTime && node.duration) {
            let fillColor: string;
            if (selection?.highlightedNodes.includes(id)) {
                fillColor = fillColors.highlighted;
            } else if (selection?.selectedNode?.uuid === id) {
                fillColor = fillColors.selected;
            } else {
                fillColor = fillColors[node.type];
            }
            data.push({
                x: id,
                y: [node.startTime, new Date(node.startTime + node.duration).getTime()],
                fillColor,
            });
        }
    }

    function createNodeLabel(node: CTDatum | undefined): string | undefined {
        if (!node) return '';

        let label: JSX.Element = <></>;

        if (node.type === 'navigation') {
            label = <NavNode node={node} />;
        } else if (node.type === 'extraction') {
            label = <ExtractionNode node={node} />;
        } else if (node.type === 'changeDetection') {
            label = <ChangeDetectionNode node={node} />;
        } else if (node.type === 'delivery') {
            label = <DeliveryNode node={node} />;
        }
        return ReactDOMServer.renderToString(label);
    }
    // Sort by starttimes
    data.sort((a, b) => a.y[0] - b.y[0]);

    const options: ApexOptions = {
        chart: {
            type: 'rangeBar',
            toolbar: {
                tools: {
                    download: false,
                    selection: true,
                    zoomin: false,
                    zoomout: true,
                    zoom: true,
                    pan: true,
                    reset: true,
                },
            },
            events: {
                dataPointSelection: (event: any, chartContext: any, config: { dataPointIndex: number }) => {
                    const node = contextTreeMap.get(data[config.dataPointIndex].x);
                    if (node) {
                        setTimeout(() => {
                            onSelectNode(node);
                        }, 10);
                    }
                },
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                barHeight: '80%',
            },
        },
        dataLabels: {
            enabled: false,
        },

        xaxis: {
            type: 'datetime',

            labels: {
                show: false,
                // format: 'HH:mm:ss.ffff',
            },
        },
        yaxis: { show: false },

        stroke: { width: 1 },
        grid: {
            row: { opacity: 0.5 },
        },
        fill: {
            type: 'solid',
            opacity: 0.9,
        },
        theme: {
            palette: 'palette5',
            mode: 'dark',
        },
        tooltip: {
            enabled: true,
            followCursor: true,
            custom: ({ dataPointIndex }: { dataPointIndex: number }) => {
                const id = data[dataPointIndex].x;
                const node = contextTreeMap.get(id);
                const mainInfo = createNodeLabel(node);
                const html = `
                    ${mainInfo}
                    <div style="font-size: 12px; padding-left: 1px">
                        Start: ${node?.startTime ? new Date(node.startTime).toISOString() : 'N/A'} <br/>
                        Duration: ${node?.duration}ms
                    </div>`;
                return html;
            },
            marker: { show: true },
            fixed: { enabled: false },
        },
    };

    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // We must resort to having listeners directly on the svg elements instead of using
        // the apexcharts events API as using them for our purposes will cause a rerender
        // which ends up removing tooltips.
        const highlight = (i: number) => {
            const id = data[i].x;
            setTimeout(() => onHighlightNode('uuid', id), 10);
        };
        const clear = (i: number) => {
            const id = data[i].x;
            if (selection?.highlightedNodes.includes(id)) {
                clearHighlight(id);
            }
        };
        let paths: NodeList;
        if (containerRef.current) {
            paths = containerRef.current.querySelectorAll('.apexcharts-series path');

            paths.forEach((path, i) => {
                path.addEventListener('mouseenter', () => highlight(i));
                path.addEventListener('mouseleave', () => clear(i));
            });
        }
        // Unnecessary to remove event listeners as the svg elements
        // are destroyed on each render
    });

    return (
        <div id="wam-timeline-chart" ref={containerRef}>
            <b style={{ paddingLeft: 3 }}>Timeline</b>

            {(data.length && (
                <Chart options={options} series={[{ data }]} type="rangeBar" height={`${data.length * 25 + 50}px`} />
            )) || <p>No data</p>}
        </div>
    );
};

export default React.memo(Timeline);
