import React, { useEffect, useState, useRef } from 'react';
import { ContextTreeMap, CTDatum } from './runTreeTypes';
import { Tree, TreeNode } from 'react-organizational-chart';
import NavNode from './NavNode';
import StartingNode from './StartingNode';
import DeliveryNode from './DeliveryNode';
import ExtractionNode from './ExtractionNode';
import ChangeDetectionNode from './ChangeDetectionNode';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

type OrgChartProps = {
    contextTreeMap: ContextTreeMap;
    rootNodeId: string;
};
export default function OrgChart({ contextTreeMap, rootNodeId}: OrgChartProps): JSX.Element {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [treeWidth, setTreeWidth] = useState<string>('100%');
    useEffect(() => {
        if (wrapperRef?.current) {
            const treeElem = wrapperRef.current.querySelector('ul');
            if (treeElem) {
                const style = window.getComputedStyle(treeElem);
                setTreeWidth(style.width);
            }
        }
    }, [wrapperRef?.current, treeWidth]);


    function createTreeNode(node: CTDatum | undefined): React.ReactElement {
        if (!node) return <></>;

        let label: React.ReactElement = <></>;

        if (node.type === 'navigation') {
            label = <NavNode node={node} />;
        } else if (node.type === 'extraction') {
            label = <ExtractionNode node={node} />;
        } else if (node.type === 'changeDetection') {
            label = <ChangeDetectionNode node={node} />;
        } else if (node.type === 'delivery') {
            label = <DeliveryNode node={node} />;
        } else {
            return <></>;
        }
        return (
            <TreeNode label={label} key={node.uuid}>
                {node?.children.map((childId) => {
                    const child = contextTreeMap.get(childId);
                    return createTreeNode(child);
                })}
            </TreeNode>
        );
    }

    const root = contextTreeMap.get(rootNodeId);
    if (!root) return <></>;

    return (
        <div ref={wrapperRef} style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div className="vertical-tree-label" style={{ position: 'relative', top: 0, left: 0 }}>
                CRAWL
            </div>
            <TransformWrapper
                minScale={0.3}
                maxScale={3}
                centerOnInit
                zoomAnimation={{ disabled: true }}
                limitToBounds={false}
            >
                <TransformComponent
                    wrapperStyle={{ height: 'calc(100% - 21px)', width: '100%' }}
                    contentStyle={{ height: '100%', width: treeWidth }}
                >
                    <Tree
                        lineWidth="1px"
                        lineColor="rgb(1, 219, 208)"
                        lineBorderRadius="4px"
                        label={<StartingNode node={root} />}
                        data-run-tree
                    >
                        {root.children.map((childId) => {
                            const child = contextTreeMap.get(childId);
                            return createTreeNode(child);
                        })}
                    </Tree>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
}
