import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material';

interface ConfirmationDialogPropsType {
    title: string;
    isOpen: boolean;
    buttons: {
        label: string;
        onClick: () => void;
        focus?: boolean;
    }[];
    onClose: () => void;
}

const ConfirmationDialog = (props: ConfirmationDialogPropsType): JSX.Element => {
    return (
        <Dialog
            open={props.isOpen}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    props.onClose();
                }
            }}
        >
            <DialogContent dividers>
                <DialogContentText>{props.title}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.buttons.map((button, idx) => (
                    <Button
                        key={`button-${idx}`}
                        onClick={() => {
                            button.onClick();
                        }}
                        color="primary"
                        autoFocus={button.focus}
                    >
                        {button.label}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
