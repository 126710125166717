/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Box, Checkbox, Divider, FormControlLabel, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MsgSettings from './MsgSettings';
import Occurrence from './Occurrence';
import { UserInfoType } from './UserInput';
import { AlertNotificationOccurrenceType, AlertNotificationType } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
    frequencyBox: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    observationWindowBox: {
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    consecutively: {
        marginTop: theme.spacing(1),
    },
    notificationType: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    notificationOptions: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
    wiOptionBox: {
        padding: theme.spacing(1),
    },
}));

interface AlertNotificationPropsType {
    occurrenceConfig: AlertNotificationOccurrenceType;
    notifications: AlertNotificationType;
    onQueryUsers: (query: string, maxRecords: number) => Promise<{ matches: UserInfoType[]; errormsg?: string }>;
    onChange: (occurrenceConfig: AlertNotificationOccurrenceType, notifications: AlertNotificationType) => void;
}

const AlertNotification = (props: AlertNotificationPropsType): JSX.Element => {
    const classes = useStyles();

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Typography variant="h6" gutterBottom>
                Notifications
            </Typography>

            <Divider />

            <Occurrence
                occurrenceConfig={props.occurrenceConfig}
                onChange={(occurrenceConfig) => props.onChange(occurrenceConfig, props.notifications)}
            />

            <Box className={classes.notificationType}>
                <Typography>Notification Type</Typography>

                <Box display="flex" flexDirection="column" className={classes.notificationOptions}>
                    <FormControlLabel
                        className={classes.wiOptionBox}
                        control={
                            <Checkbox
                                checked={props.notifications.createWorkItem}
                                onChange={(event, checked) => {
                                    const newNotifications = { ...props.notifications, createWorkItem: checked };
                                    props.onChange(props.occurrenceConfig, newNotifications);
                                }}
                            />
                        }
                        label="Create Work Item"
                    />

                    <MsgSettings
                        msgSettings={props.notifications.sendMsgSettings}
                        onQueryUsers={props.onQueryUsers}
                        onChange={(msgSettings) => {
                            const newNotifications = { ...props.notifications, sendMsgSettings: msgSettings };
                            props.onChange(props.occurrenceConfig, newNotifications);
                        }}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default AlertNotification;
