import React from 'react';
import { IProvenanceTreeNode } from './runTreeTypes';
import ViewerLabel from './ViewerLabel';
import JsonViewer from './JsonViewer';

export type ProvenanceViewerProps = {
    node: IProvenanceTreeNode;
};
const ProvenanceViewerProps = ({ node }: ProvenanceViewerProps): React.ReactElement => {
    return (
        <>
            <ViewerLabel name={node.data.agentName} description={node.data.stepId} />
            <div className="data-viewer-content data-viewer-json">
                <JsonViewer data={node.data} name="event" />
            </div>
        </>
    );
};

export default ProvenanceViewerProps;
