import React, { useContext, useEffect, useState } from 'react';
import { GeckoDeliveries } from '@webacq/wa-shared-definitions';
import ViewerLabel from './ViewerLabel';
import { LinkHandlerContext } from './hooks';

export type DeliveryViewerProps = {
    delivery: GeckoDeliveries;
    baseUri?: string;
};

const DeliveryViewer = ({
    delivery,
    baseUri,
}: DeliveryViewerProps): React.ReactElement => {
    const [url, setUrl] = useState('');
    const [content, setContent] = useState<string>();
    const [contentDoc, setContentDoc] = useState<string>();
    const {
        openBcosViewer,
        onFetchContent
    } = useContext(LinkHandlerContext);

    useEffect(() => {
        const urlValue = typeof delivery.raw.location === 'string' ? delivery.raw.location : delivery.raw.location.url;
        setUrl(urlValue);
    }, [delivery]);

    useEffect(() => {
        if (url) {
            // requesting base64-encoded content to be able to properly handle binary data
            onFetchContent(url, true).then(({ headers, content }) => {
                const contentType = headers['content-type'] || 'text/html';

                if (/text\/html/i.test(contentType)) {
                    const stringContent = Buffer.from(content, 'base64').toString();

                    /**
                     * base->href is needed in order to make scheme relative sub-resources work, i.e. absolute URIs starting with //
                     * although string replacement isn't the best solution, it's the most simple. The alternative is to append a BASE
                     * element using JS after the iframe's DOMContentLoaded is triggered, but Chromium IFrame's contentDocument gets replaced entirely
                     * when setting srcdoc, which makes the only feasible solution to use setTimeout and keep checking for changes.
                     *
                     * As the below solution works for the use cases we've seen, I elected to use it for now
                     * till we find a common use case that doesn't work
                     * more info: https://stackoverflow.com/questions/16960829/detect-domcontentloaded-in-iframe
                     */

                    const contentWithBase = stringContent.replace(
                        '<head>',
                        `<head><base href="${baseUri || 'https://www.bloomberg.com'}" />`
                    );

                    setContent(undefined);
                    setContentDoc(contentWithBase);
                } else {
                    // content already is base64-encoded

                    setContentDoc(undefined);
                    setContent(`data:${contentType};base64,${content}`);
                }
            });
        }
    }, [url]);

    // useEffect(() => {
    //     if (iframeRef.current) {
    //         console.log('current iframe baseurl: ', iframeRef.current.baseURI);
    //         const contentDocument = iframeRef.current.contentDocument;
    //         iframeRef.current.contentWindow?.addEventListener("")
    //         if (contentDocument) {
    //             if (contentDocument.URL.startsWith("about:")) {
    //                 setTimeout
    //             }
    //             contentDocument.addEventListener("DOMContentLoaded", function () {
    //                 console.log('iframe DOMContentLoaded');
    //                 const baseElement = contentDocument.createElement("base");
    //                 baseElement.setAttribute('href', 'https://www.bloomberg.com');
    //                 contentDocument.head.appendChild(baseElement);
    //                 contentDocument.removeEventListener("DOMContentLoaded", arguments.callee as EventListenerOrEventListenerObject, false);
    //                 console.log('current iframe baseurl2: ', iframeRef.current?.baseURI);
    //             }, false);
    //         } else {
    //             console.log('no content element');
    //         }
    //         // iframeRef.current.contentDocument?.createElement()
    //         // iframeRef.current.baseURI = "https://www.example.com";
    //     }

    // }, [iframeRef]);

    return (
        <>
            <ViewerLabel name="Delivery" description={<a onClick={() => openBcosViewer(url)}>{url}</a>} />
            <div
                className="data-viewer-content"
                style={{
                    overflow: 'initial',
                }}
            >
                <div className="delivery-viewer">
                    <iframe src={content} srcDoc={contentDoc} height="100%" width="100%" />
                </div>
            </div>
        </>
    );
};

export default DeliveryViewer;
