import React from 'react';
import ViewerLabel from './ViewerLabel';

export type ExtractionViewerProps = {
    data: Record<string, any>;
    onSelectRow?: (index: number | undefined) => void;
};

const ExtractionViewer = ({ data: columns }: ExtractionViewerProps): React.ReactElement => {
    const colNames = Object.keys(columns);
    if (!colNames.length) return <></>;

    const table = new Array(colNames.length);
    const numRows = Math.max(
        ...Object.values(columns).map((v: string[]) => {
            if (!v.values && Array.isArray(v)) return v.length;
            return v.values.length;
        }),
    );

    for (let j = 0; j < numRows; j++) {
        table[j] = {};
        colNames.forEach((name) => {
            const vals = columns[name].values;
            table[j][name] = vals[j];
        });
    }

    return (
        <>
            <ViewerLabel name="Extraction" description={`${numRows} rows`} />
            <div className="provenance-content-container">
                <table className="data-viewer-table">
                    <thead>
                        <tr>
                            {colNames.map((column) => (
                                <td key={column}>{column}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {table.map((row: Record<string, string>, i) => (
                            <tr
                                className="data-viewer-table-row"
                                key={i}
                                style={{
                                    textAlign: 'start',
                                    borderBottom: '1px solid white',
                                    color: '#fff',
                                }}
                            >
                                {Object.entries(row).map((entry, i: number) => (
                                    <td key={i}>{entry[1]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ExtractionViewer;
