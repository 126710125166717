/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Box, FormControl, MenuItem, Select, TextField, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlysAlertCondition, AlysMetric, AlysOperator } from '@webacq/wa-shared-definitions';
import MetricSelector from './MetricSelector';

const useStyles = makeStyles((theme: Theme) => ({
    operator: {
        marginLeft: theme.spacing(1),
        width: '8ch',
    },
    operand: {
        marginLeft: theme.spacing(1),
    },
    numberInput: {
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
}));

interface AlertConditionPropsType {
    metrics: AlysMetric[];
    operators: AlysOperator[];
    condition: AlysAlertCondition;
    autoFocus?: boolean;
    onChange: (condition: AlysAlertCondition) => void;
}

const AlertCondition = (props: AlertConditionPropsType): JSX.Element => {
    const classes = useStyles();

    const numOperands = props.operators.find((operator) => operator.id === props.condition.operator)?.arity || 2;

    return (
        <Box display="flex">
            <MetricSelector
                metrics={props.metrics}
                selectedMetricId={props.condition.metric}
                onChange={(metricId) =>
                    props.onChange({
                        ...props.condition,
                        metric: metricId,
                    })
                }
            />

            <FormControl size="small">
                <Select
                    variant="outlined"
                    className={classes.operator}
                    value={props.condition.operator}
                    onChange={(event) =>
                        props.onChange({
                            ...props.condition,
                            operator: event.target.value as AlysOperator['id'],
                        })
                    }
                >
                    {props.operators.map((operator) => (
                        <MenuItem key={operator.id} value={operator.id}>
                            {operator.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {Array.from({ length: numOperands - 1 }).map((_, idx) => (
                <Tooltip
                    key={`operand-${idx}`}
                    title={props.metrics.find((metric) => metric.id === props.condition.metric)?.unit || ''}
                >
                    <Box className={classes.operand}>
                        <TextField
                            type="number"
                            className={classes.numberInput}
                            size="small"
                            variant="outlined"
                            value={
                                props.condition.operands && props.condition.operands[idx] !== undefined
                                    ? props.condition.operands[idx]
                                    : ''
                            }
                            error={props.condition.operands && props.condition.operands[idx] === undefined}
                            autoFocus={props.autoFocus}
                            inputProps={{
                                style: {
                                    textAlign: 'right',
                                    width: '5ch',
                                },
                                min: 0,
                            }}
                            onChange={(event) => {
                                const newOperands = [...(props.condition.operands || [])];
                                newOperands[idx] = event.target.value ? parseInt(event.target.value) : undefined;
                                props.onChange({
                                    ...props.condition,
                                    operands: newOperands,
                                });
                            }}
                        />
                    </Box>
                </Tooltip>
            ))}
        </Box>
    );
};

export default AlertCondition;
