import React, { useState } from 'react';
import { Dialog } from '@mui/material';
import { makeStyles } from '@mui/styles';
import JobConfigCard from './JobConfigCard';
import { JobConfigSearchDoc } from '@webacq/wa-shared-definitions';

type SupportedDocTypes = JobConfigSearchDoc;

export type CardListProps = {
    data: SupportedDocTypes[];
    renderCardActions?: (data: SupportedDocTypes) => JSX.Element;
    onUrlClick?: (url: string) => void;
};

const useStyles = makeStyles({
    grid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    tile: {
        margin: 8,
    },
});
const CardList = ({ data, renderCardActions, onUrlClick }: CardListProps): JSX.Element => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState<SupportedDocTypes | null>(null);

    const handleUrlClick = (url: string): void => {
        if (onUrlClick) {
            onUrlClick(url);
        }
    };

    return (
        <div className={classes.grid}>
            {data.map((d) => {
                if (d.type === 'JOB_CFG') {
                    return (
                        <div className={classes.tile} key={d.id}>
                            <JobConfigCard
                                jobConfig={d}
                                onUrlClick={handleUrlClick}
                                renderCardActions={renderCardActions}
                                onExpandClick={(): void => setExpanded(d)}
                            />
                        </div>
                    );
                } else {
                    return <></>;
                }
            })}
            {expanded && (
                <Dialog open={!!expanded} onClose={(): void => setExpanded(null)}>
                    <JobConfigCard
                        jobConfig={expanded}
                        onUrlClick={handleUrlClick}
                        onExpandClick={(): void => setExpanded(expanded)}
                        renderCardActions={renderCardActions}
                        width="auto"
                        fullDetails
                    />
                </Dialog>
            )}
        </div>
    );
};

export default CardList;
