import React, { useContext, createContext } from 'react';
import {
    GeckoContextTree,
    GeckoOutputBody,
    GeckoDeliveries,
    Timeline,
    BbdsMsgWamTimeline,
} from '@webacq/wa-shared-definitions';
import {
    RawNodeDatum,
    CTDatum,
    ArrowRelationshipType,
    SelectedData,
    IProvenanceRecord,
    IProvenanceNode,
} from './runTreeTypes';

const makeNavNode = (ct: GeckoContextTree, parent: string): CTDatum => {
    return {
        uuid: ct.uuid,
        parent,
        type: 'navigation',
        geckoNodeId: ct.navigationNodeId || '',
        body: ct,
        children: [],
        url: ct.url,
    };
};

const makeExtractionNode = (
    uuid: string,
    val: GeckoOutputBody,
    geckoNodeId: string,
    parent: string,
    url?: string,
): CTDatum => {
    return {
        uuid,
        parent,
        type: 'extraction',
        geckoNodeId,
        body: val as GeckoOutputBody,
        children: [],
        url,
    };
};

const makeChangeDetectionNode = (
    uuid: string,
    val: GeckoOutputBody,
    geckoNodeId: string,
    parent: string,
    url?: string,
): CTDatum => {
    return {
        uuid,
        parent,
        type: 'changeDetection',
        geckoNodeId,
        body: val as GeckoOutputBody,
        children: [],
        url,
    };
};

const makeDeliveryNode = (
    deliveries: GeckoDeliveries,
    deliveryId: string,
    parent: string,
    geckoNodeId: string,
    url?: string,
): CTDatum => {
    return {
        uuid: `${parent}__${deliveryId}`,
        parent: parent,
        type: 'delivery',
        geckoNodeId,
        body: deliveries,
        children: [],
        url,
    };
};

function findExtractions(body: GeckoOutputBody | undefined, geckoGraph: Record<string, any>): [string, any][] {
    return Object.entries(body || {}).filter(([id, val]) => {
        const node = geckoGraph.nodes[id];
        return node?.nodeType === 'extraction';
    });
}

function findCdNodes(body: GeckoOutputBody | undefined, geckoGraph: Record<string, any>): [string, any][] {
    return Object.entries(body || {}).filter(([id, val]) => {
        const node = geckoGraph.nodes[id];
        return node?.nodeType === 'changeDetection';
    });
}


function findCdForExt(extId: string, geckoGraph: Record<string, any>): string | undefined {
    const children: string[] = geckoGraph.links[extId]?.sinks;
    if (!children?.length) return undefined;
    const childCdId = children.find((id) => geckoGraph.nodes[id]?.nodeType === 'changeDetection');
    return childCdId;
}

function getNodeTiming(nodeId: string, contextTree: GeckoContextTree): [number, number] | [] {
    const timing = contextTree.timeline?.find((t) => t.name === nodeId) as Timeline | BbdsMsgWamTimeline;
    if (!timing) return [];
    const duration = timing.duration;
    let startTime = 0;
    if ('unix_start_time_ms' in timing) {
        startTime = timing.unix_start_time_ms as number;
    } else if ('unixStartTimeMs' in timing) {
        startTime = timing.unixStartTimeMs as number;
    } else {
        return [];
    }
    return [startTime, duration];
}

/**
 * Breaks down the context tree into a "tree map" that conveys the heierarchy of the output.
 */
export const useConstructOrgChartData = (
    data: GeckoContextTree,
    geckoGraph: Record<string, any>,
): { treeMap: Map<string, CTDatum> } => {
    const treeMap = new Map<string, CTDatum>();

    function transformData(ct: GeckoContextTree, parent = ''): CTDatum {
        const navNode = makeNavNode(ct, parent);
        const bodyIds = Object.keys(ct.body || {});
        const extractionEntries = findExtractions(ct.body, geckoGraph);
        const cdNodes = findCdNodes(ct.body, geckoGraph);

        let lastStep: CTDatum = navNode;

        // If there's a body, the Nav node has children Extraction nodes
        if (ct.body && bodyIds.length && ct.navigationNodeId) {
            // If there are no columns from Extractions, add a CD node as a direct child of the Nav.
            // Otherwise, the CD nodes will be added below the Extraction nodes further on.
            if (!extractionEntries.length && cdNodes.length) {
                cdNodes.forEach(([cdGeckoId]) => {
                    const cdUuid = `${navNode.uuid}__${cdGeckoId}`;
                    const cdNode = makeChangeDetectionNode(
                        cdUuid,
                        ct.body![cdGeckoId as keyof typeof ct.body] as GeckoOutputBody,
                        cdGeckoId,
                        navNode.uuid,
                        ct.url,
                    );

                    navNode.children.push(cdUuid);
                    const [startTime, duration] = getNodeTiming(cdGeckoId, ct);
                    if (startTime && duration) {
                        cdNode.startTime = startTime;
                        cdNode.duration = duration;
                    }
                    treeMap.set(cdUuid, cdNode);
                    lastStep = cdNode;
                });
            } else {
                // The context body has Extraction columns and CD columns in the same level.
                // Here, we want to show the CD as a child of the Extraction, like the gecko graph
                extractionEntries.forEach(([extGeckoId, val]) => {
                    const extUuid = `${navNode.uuid}__${extGeckoId}`;
                    const extNode = makeExtractionNode(extUuid, val as GeckoOutputBody, extGeckoId, ct.uuid, ct.url);
                    const [startTime, duration] = getNodeTiming(extGeckoId, ct);
                    if (startTime && duration) {
                        extNode.startTime = startTime;
                        extNode.duration = duration;
                    }

                    treeMap.set(extNode.uuid, extNode);
                    navNode.children.push(extNode.uuid);
                    lastStep = extNode;
                    const cdGeckoId = findCdForExt(extGeckoId, geckoGraph);
                    if (cdGeckoId && ct.body && ct.body[cdGeckoId as keyof typeof ct.body]) {
                        const cdUuid = `${navNode.uuid}__${cdGeckoId}`;
                        const cdNode = makeChangeDetectionNode(
                            cdUuid,
                            ct.body[cdGeckoId as keyof typeof ct.body] as GeckoOutputBody,
                            cdGeckoId,
                            extUuid,
                            ct.url,
                        );

                        extNode.children.push(cdUuid);
                        const [startTime, duration] = getNodeTiming(cdGeckoId, ct);
                        if (startTime && duration) {
                            cdNode.startTime = startTime;
                            cdNode.duration = duration;
                        }

                        treeMap.set(cdUuid, cdNode);
                        lastStep = cdNode;

                        if (!ct.children?.length) return;
                        ct.children
                            .filter((child) => {
                                const path = child.source?.path || '';
                                return path.split('/').includes(extGeckoId);
                            })
                            .forEach((c) => {
                                cdNode.children.push(c.uuid);
                                transformData(c, cdNode.uuid);
                            });
                    } else {
                        if (!ct.children?.length) return;
                        ct.children
                            .filter((child) => {
                                const path = child.source?.path || '';
                                return path.split('/').includes(extGeckoId);
                            })
                            .forEach((c) => {
                                extNode.children.push(c.uuid);
                                transformData(c, extNode.uuid);
                            });
                    }
                });
            }

            // Child Navigations
        } else if (ct.children?.length) {
            ct.children.forEach((child) => {
                navNode.children.push(child.uuid);
                transformData(child, navNode.uuid);
            });
        }

        if (ct.deliveries) {
            const geckoNodeParent = ct.navigationNodeId || '';
            const geckoParent = geckoGraph.links[geckoNodeParent];
            const delGeckoNodeId = ct.deliveryNodeId || (geckoParent?.sinks?.length && geckoParent.sinks[0]) || '';

            const deliveryNode = makeDeliveryNode(
                ct.deliveries,
                ct.deliveryNodeId || 'delivery',
                ct.uuid,
                delGeckoNodeId,
                ct.url,
            );

            const [startTime, duration] = getNodeTiming(delGeckoNodeId, ct);
            if (startTime && duration) {
                deliveryNode.startTime = startTime;
                deliveryNode.duration = duration;
            }

            treeMap.set(deliveryNode.uuid, deliveryNode);
            lastStep.children.push(deliveryNode.uuid);
        }

        const [startTime, duration] = getNodeTiming(navNode.geckoNodeId, ct);
        if (startTime && duration) {
            navNode.startTime = startTime;
            navNode.duration = duration;
        }

        treeMap.set(navNode.uuid, navNode);

        return navNode;
    }

    treeMap.set(data.uuid, {
        uuid: data.uuid,
        parent: '',
        type: 'start',
        geckoNodeId: '',
        body: data,
        children: data.children?.map((c) => c.uuid) || [],
    } as CTDatum);

    (data.children || []).forEach((c) => transformData(c, data.uuid));
    // console.log('treeMap:', treeMap);
    return { treeMap };
};

export const formatByteSize = (fileSizeInBytes: number): string => {
    // https://stackoverflow.com/q/10420352
    let i = -1;
    const byteUnits = [' KB', ' MB', ' GB', ' TB'];
    do {
        fileSizeInBytes = fileSizeInBytes / 1024;
        i++;
    } while (fileSizeInBytes > 1024);

    return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
};

export const constructGeckoTree = (gecko: Record<string, any>): Record<string, any> => {
    const { links, nodes, propagation } = gecko;
    // TODO: handle multiple starting nodes
    const root = propagation?.length && propagation[0];
    if (!root) return {};
    function compose(id: string): Record<string, any> {
        const node = nodes[id];
        if (!node) return {};

        return {
            name: id,
            attributes: { nodeType: node.nodeType },
            children: links[id]?.sinks.map(compose) || [],
        };
    }
    const data = compose(root);
    return data;
};

export const getGraphRelationships = (root: RawNodeDatum): ArrowRelationshipType[] => {
    const relationships: ArrowRelationshipType[] = [];

    function traverse(node: RawNodeDatum): void {
        (node.children || []).forEach((child) => {
            const rel: ArrowRelationshipType = {
                source: 'graph-node-' + node.name,
                sink: 'graph-node-' + child.name,
                relType: 'children',
            };
            relationships.push(rel);
            traverse(child);
        });
    }
    traverse(root);
    return relationships;
};

export const SelectedDataContext = createContext<SelectedData>({
    selectedNode: undefined,
    highlightedNodes: [],
});

export const SelectionApiContext = createContext({
    onSelectNode: (node: CTDatum | IProvenanceNode | undefined): void => {},
    onHighlightNode: (property: keyof CTDatum, val: string): void => {},
    clearHighlight: (id: string): void => {},
});

export const defaultLinkHandlerApi = {
    getBcosEndpoint: (url: string) => `${window.location.origin}/object?url=${url}`,
    onOpenUrl: (url: string) => { window.open(url, '_blank') },
    onFetchContent: async (url: string, base64?: boolean): Promise<any> => ({}),
    openBcosViewer: (url: string) => { window.open(`${window.location.origin}/object?url=${url}`, '_blank') },
    hubUrl: window.location.origin,
};

export const LinkHandlerContext = createContext(defaultLinkHandlerApi);

export const useSelectedNode = (): SelectedData | null => {
    const selected = useContext(SelectedDataContext);
    return selected;
};

export const createProvenanceTree = (
    records: IProvenanceRecord[],
    hideNonCrawl = true,
): IProvenanceNode | undefined => {
    if (!records.length) {
        return undefined;
    }
    const rootStepId = records[0].rootStepId;
    const provMap = new Map<string, IProvenanceNode>();
    if (hideNonCrawl) {
        records = records.filter(
            (r) =>
                r.rootStepId === r.stepId ||
                (r.workflowType && /^(wam|crawl)-/.test(r.workflowType)) ||
                (r.agentType && /^(wam|crawl)/i.test(r.agentType)),
        );
    }
    records.forEach((record) => {
        let eventData = record.eventData;
        // The provenance of these ridiculously encoded messages is from Provenance.
        // Try to decode them as best we can.
        try {
            eventData = JSON.parse(JSON.parse(record.eventData as string).string);
            if (typeof eventData === 'string') {
                eventData = JSON.parse(eventData);
            }
        } catch (e) {}
        const children: IProvenanceNode[] = [];

        provMap.set(record.stepId, {
            uuid: record.stepId,
            data: {
                ...record,
                eventData,
            },
            type: 'provenance',
            children,
        });
    });

    provMap.forEach((record) => {
        if (record.data.parentStepId) {
            const parent = provMap.get(record.data.parentStepId + '__data') || provMap.get(record.data.parentStepId);
            if (parent) {
                parent.children.push(record);
            }
        }
    });
    return provMap.get(rootStepId);
};
export const URL_REGEX =
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export const isValidUrl = (str: string): boolean => URL_REGEX.test(str);

export const parseXmlTable = (raw: string): Record<string, any>[] => {
    const parser = new window.DOMParser();
    const dom = parser.parseFromString(raw, 'application/xml');
    const data = dom.getElementsByTagName('DATA');

    if (!data.length) return [];

    const nodes = data[0].children;
    const columns = new Set();
    const rowData = [];

    for (const row of Array.from(nodes)) {
        const obj: Record<string, string> = {};
        for (const child of Array.from(row.children)) {
            obj[child.tagName] = child.textContent ?? '';
            columns.add(child.tagName);
        }
        rowData.push(obj);
    }

    const cols: Record<string, string | undefined> = {};
    (Array.from(columns) as string[]).map((c) => {
        cols[c] = undefined;
    });

    // Ensure that each row has all the columns
    const objects = rowData.map((row) => ({
        ...cols,
        ...row,
    }));

    return objects;
};
