import { ThemeOptions } from '@mui/material';
import { Theme } from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

// Colors are from the definitions in BB-UI framework-
// https://bbgithub.dev.bloomberg.com/bbnpm/bb-ui-framework/blob/master/src/__theme__/LightTheme.ts
export const colors = {
    black: '#000',
    white: '#fff',
    red: {
        50: '#fee6eb',
        100: '#ffa7af',
        200: '#ff7486',
        300: '#ff415c',
        400: '#ff2c4a',
        500: '#e61e3c',
        600: '#c31834',
        700: '#a1122c',
        800: '#7e0c24',
        900: '#5c061c',
        1000: '#390014',
    },
    orange: {
        50: '#ffddd3',
        100: '#fdc8b7',
        200: '#fdac93',
        300: '#fc9170',
        400: '#fb754c',
        500: '#fa5a28',
        600: '#d64d22',
        700: '#b3401d',
        800: '#bf3317',
        900: '#6b2711',
        1000: '#471a0b',
    },
    amber: {
        100: '#ffdfb7',
        200: '#ffd093',
        300: '#ffc070',
        400: '#ffb04c',
        500: '#ffa028',
        600: '#db8922',
        700: '#b6721d',
        800: '#925b17',
        900: '#6d4511',
        1000: '#492e0b',
    },
    yellow: {
        100: '#fff9cc',
        200: '#fff399',
        300: '#ffed66',
        400: '#ffe733',
        500: '#ffe100',
        600: '#d7be00',
        700: '#b09b00',
        800: '#887800',
        900: '#615500',
        1000: '#393200',
    },
    green: {
        50: '#EAF8E9',
        100: '#c5facf',
        200: '#8af59e',
        300: '#50f06e',
        400: '#35d644',
        500: '#1bbc3c',
        600: '#00a223',
        700: '#00851c',
        800: '#006716',
        900: '#004a0f',
        1000: '#002c09',
    },
    teal: {
        100: '#dffcff',
        200: '#bff9ff',
        300: '#80f3ff',
        400: '#40ecff',
        500: '#00e6ff',
        600: '#00c2d7',
        700: '#009eb0',
        800: '#007b88',
        900: '#005761',
        1000: '#003339',
    },
    blue: {
        50: '#F3F8FF',
        100: '#cce3ff',
        200: '#99c7ff',
        300: '#66abff',
        400: '#2b8eff',
        500: '#0073ff',
        600: '#0062dd',
        700: '#0051ba',
        800: '#004098',
        900: '#003075',
        1000: '#001f53',
        1100: '#001232',
    },
    purple: {
        50: '#f8e5fb',
        100: '#edd0ff',
        200: '#e4b9ff',
        300: '#daa2ff',
        400: '#d18aff',
        500: '#c873ff',
        600: '#ab63db',
        700: '#8f52b6',
        800: '#724292',
        900: '#56316d',
        1000: '#392149',
    },
    magenta: {
        50: '#fcdff4',
        100: '#fbb7e8',
        200: '#f993dd',
        300: '#f770d2',
        400: '#f54cc6',
        500: '#f328bb',
        600: '#d022a0',
        700: '#ae1d86',
        800: '#8b176b',
        900: '#681150',
        1000: '#450b35',
    },
    grey: {
        50: '#f8f8fa',
        100: '#f0f0f0',
        125: '#ebebeb',
        150: '#e1e1e1',
        200: '#d7d7d7',
        250: '#cdcdcd',
        300: '#bebebe',
        350: '#a5a5a5',
        400: '#8c8c8c',
        450: '#787878',
        500: '#646464',
        550: '#505050',
        600: '#414141',
        650: '#373737',
        700: '#282828',
        750: '#232323',
        800: '#1e1e1e',
        850: '#191919',
        900: '#141414',
    },
};

export const darkTheme: ThemeOptions = {
    palette: {
        mode: 'dark',
        background: {
            default: colors.grey[850],
            paper: colors.grey[700],
        },
        text: {
            primary: colors.grey[50],
            disabled: colors.grey[350],
        },
        primary: {
            dark: colors.blue[500],
            light: colors.blue[300],
            main: colors.blue[400],
        },
        secondary: {
            dark: colors.amber[600],
            light: colors.amber[500],
            main: colors.amber[500],
        },
        grey: {
            ...colors.grey,
        },
    },
    typography: {
        fontFamily: ['"AvenirNextP2ForBBG"', '"AvenirNextForBBG"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(', '),
        fontSize: 14,
        body1: {
            fontSize: '.875rem'
        },
        body2: {
            fontSize: '.875rem'
        },
        button: {
            textTransform: 'none',
            fontWeight: 700,
        },

    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundColor: '#282828',
                    backgroundImage: 'none',
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: colors.grey[600],
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
            defaultProps: {
                size: 'small'
            },
        },
        MuiSelect: {
            defaultProps: {
                size: 'small'
            },
        },
        MuiInput: {
            defaultProps: {
                size: 'small'
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '4px 8px',
                },
            }
        },
    },
};
