/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Box, Checkbox, Divider, FormControlLabel, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RangeSelector from './RangeSelector';
import UserInput, { UserInfoType } from './UserInput';
import { AlertNotificationMsgSettings, AlertNotificationRangeUnitType } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
    spacing: {
        marginLeft: theme.spacing(2),
    },
    msgOptionBox1: {
        padding: theme.spacing(1),
        borderColor: 'white',
        borderWidth: 1,
        borderStyle: 'solid',
    },
    msgOptionBox2: {
        padding: theme.spacing(1),
    },
    msgFrequencyBox: {
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(2),
    },
    msgRecipientsBox: {
        marginLeft: theme.spacing(4),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

interface MsgSettingsPropsType {
    msgSettings: AlertNotificationMsgSettings;
    onQueryUsers: (query: string, maxRecords: number) => Promise<{ matches: UserInfoType[]; errormsg?: string }>;
    onChange: (msgSettings: AlertNotificationMsgSettings) => void;
}

const MsgSettings = (props: MsgSettingsPropsType): JSX.Element => {
    const classes = useStyles();

    return (
        <Box className={props.msgSettings ? classes.msgOptionBox1 : classes.msgOptionBox2}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={props.msgSettings.enabled}
                        onChange={(event, checked) => {
                            const newMsgSettings = {
                                ...props.msgSettings,
                                enabled: checked,
                            };
                            props.onChange(newMsgSettings);
                        }}
                    />
                }
                label="Send MSG Alert"
            />
            {props.msgSettings.enabled && (
                <Box>
                    <Divider />

                    <Box display="flex" alignItems="center" className={classes.msgFrequencyBox}>
                        <Typography>Send once every</Typography>

                        <Box className={classes.spacing}>
                            <RangeSelector
                                unitsToOmit={[
                                    AlertNotificationRangeUnitType.TIMES,
                                    AlertNotificationRangeUnitType.RUNS,
                                ]}
                                range={props.msgSettings.data.frequency}
                                onChange={(range) => {
                                    const newMsgSettings = {
                                        ...props.msgSettings,
                                    };
                                    newMsgSettings.data.frequency = range;
                                    props.onChange(newMsgSettings);
                                }}
                            />
                        </Box>
                    </Box>

                    <Box display="flex" alignItems="center" className={classes.msgRecipientsBox}>
                        <Typography>Recipients</Typography>

                        <Box className={classes.spacing} width="100%">
                            <UserInput
                                users={props.msgSettings.data.recipients}
                                onQueryUsers={props.onQueryUsers}
                                onChange={(users) => {
                                    if (props.msgSettings) {
                                        const newMsgSettings = {
                                            ...props.msgSettings,
                                        };
                                        newMsgSettings.data.recipients = [...users];
                                        props.onChange(newMsgSettings);
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default MsgSettings;
