import React from 'react';
import { Snackbar, SnackbarOrigin } from '@mui/material';
import { Alert, AlertTitle } from '@mui/lab';

type ToastProps = {
    open: boolean;
    title?: JSX.Element | string;
    message?: JSX.Element | string;
    severity: 'error' | 'warning' | 'success' | 'info';
    position?: ToastPosition;
    onClose?: () => void;
    duration?: number;
    variant?: 'filled' | 'outlined';
    action?: React.ReactNode;
};

export enum ToastPosition {
    TOP_LEFT,
    TOP_CENTER,
    TOP_RIGHT,
    BOTTOM_LEFT,
    BOTTOM_CENTER,
    BOTTOM_RIGHT,
}

const getOrigin = (position?: ToastPosition): SnackbarOrigin | undefined => {
    switch (position) {
        case ToastPosition.TOP_LEFT:
            return {
                vertical: 'top',
                horizontal: 'left',
            };

        case ToastPosition.TOP_CENTER:
            return {
                vertical: 'top',
                horizontal: 'center',
            };

        case ToastPosition.TOP_RIGHT:
            return {
                vertical: 'top',
                horizontal: 'right',
            };

        case ToastPosition.BOTTOM_LEFT:
            return {
                vertical: 'bottom',
                horizontal: 'left',
            };

        case ToastPosition.BOTTOM_CENTER:
            return {
                vertical: 'bottom',
                horizontal: 'center',
            };

        case ToastPosition.BOTTOM_RIGHT:
            return {
                vertical: 'bottom',
                horizontal: 'right',
            };

        default:
    }

    return undefined;
};

const Toast = (props: ToastProps): JSX.Element => {
    return (
        <Snackbar
            open={props.open}
            autoHideDuration={props.duration || null}
            anchorOrigin={getOrigin(props.position)}
            onClose={props.onClose}
        >
            <Alert onClose={props.onClose} variant={props.variant} severity={props.severity} action={props.action}>
                {props.title && <AlertTitle>{props.title}</AlertTitle>}
                {props.message}
            </Alert>
        </Snackbar>
    );
};

export default Toast;
