import React, { MouseEventHandler, useContext } from 'react';
import clsx from 'clsx';
import { SelectionApiContext, useSelectedNode } from './hooks';
import { CTDatum } from './runTreeTypes';

export type ChangeDetectionNodeProps = {
    node: CTDatum;
    onMouseOver?: MouseEventHandler<SVGElement>;
    isHighlighted?: boolean;
};

type CDOutput = {
    isNew: {
        values: string[];
    };
};

const ChangeDetectionNode = ({ node }: ChangeDetectionNodeProps): JSX.Element => {
    const selection = useSelectedNode();
    const isSelected = selection?.selectedNode?.uuid === node.uuid;
    const isHighlighted = selection?.highlightedNodes.includes(node.uuid);
    const { onSelectNode, onHighlightNode, clearHighlight } = useContext(SelectionApiContext);

    // TODO: this assumes the ouput field name is always 'isNew',
    // but it's possible this might be configurable in the future.
    const newRows = (((node.body as CDOutput).isNew?.values as string[]) || []).filter((v: string) => v === 'true');

    return (
        <div className="context-tree-node-wrapper">
            <div
                className={clsx('context-tree-node-base', 'change-detection-node', {
                    'selected-node': isSelected,
                    'highlighted-node': isHighlighted,
                })}
                onClick={(e): void => {
                    onSelectNode(node);
                    e.stopPropagation();
                }}
                onMouseEnter={() => {
                    onHighlightNode('uuid', node.uuid);
                }}
                onMouseLeave={() => {
                    clearHighlight(node.uuid);
                }}
            >
                <div className={clsx('context-tree-node', { 'selected-node': isSelected })}>
                    <b className="node-title">Change Detection </b>
                    <br />
                    {newRows.length || 0} new rows
                </div>
            </div>
        </div>
    );
};

export default ChangeDetectionNode;
