/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import {
    Autocomplete,
    AutocompleteRenderGetTagProps,
    Chip,
    CircularProgress,
    InputAdornment,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useDebounce } from 'react-use';

interface DynamicAutoCompletePropsType<T> {
    value: T[];
    getOptions: (query: string) => Promise<T[]>;
    getOptionLabel: (option: string | T) => string;
    renderOption?: (props: React.HTMLAttributes<HTMLLIElement>, option: T) => React.ReactNode;
    renderTag?: (option: T, idx: number, props: {}) => React.ReactNode;
    filterOptions?: (options: T[]) => T[];
    onChange?: (values: T[]) => void;
    errormsg?: string;
}

const DynamicAutoComplete = <T extends object>(props: DynamicAutoCompletePropsType<T>): JSX.Element => {
    const [query, setQuery] = React.useState('');

    const [options, setOptions] = React.useState<T[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);

    useDebounce(
        async () => {
            setIsLoading(true);
            const options = await props.getOptions(query);
            setOptions(options);
            setIsLoading(false);
        },
        500,
        [query]
    );

    const renderTag = props.renderTag;

    return (
        <Autocomplete
            multiple
            fullWidth
            freeSolo
            autoHighlight
            autoSelect
            handleHomeEndKeys
            value={props.value}
            open={options.length >= 0}
            getOptionLabel={props.getOptionLabel}
            renderOption={props.renderOption}
            renderTags={
                renderTag
                    ? (values: T[], getTagProps: AutocompleteRenderGetTagProps) =>
                          values.map((value, index) => renderTag(value, index, getTagProps({ index })))
                    : undefined
            }
            filterOptions={props.filterOptions}
            // limitTags={3}
            // getLimitTagsText={(more) => (
            //     <Tooltip
            //         title={
            //             <ul>
            //                 {props.value.slice(-more).map((val, idx) => (
            //                     <li key={idx}>{props.getOptionLabel(val)}</li>
            //                 ))}
            //             </ul>
            //         }
            //     >
            //         <span>+{more}</span>
            //     </Tooltip>
            // )}
            options={options}
            loading={isLoading}
            onChange={(_, values) => {
                setOptions([]);
                if (props.onChange) {
                    props.onChange(values.filter((value) => typeof value !== 'string') as T[]);
                }
            }}
            onBlur={() => setOptions([])}
            renderInput={(params) => (
                <TextField
                    {...params}
                    helperText={props.errormsg}
                    error={!!props.errormsg}
                    onChange={(e) => {
                        setQuery(e.target.value);
                    }}
                    onFocus={(e) => {
                        setQuery('');
                        setQuery(e.target.value);
                    }}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                {/* this will have the chips w/ the user names */}
                                {params.InputProps.startAdornment}
                            </>
                        ),
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export type UserInfoType = {
    uuid: number;
    name?: string;
    firm?: string;
};

interface UserInputPropsType {
    users: UserInfoType[];
    onQueryUsers: (query: string, maxRecords: number) => Promise<{ matches: UserInfoType[]; errormsg?: string }>;
    onChange?: (users: UserInfoType[]) => void;
}

const UserInput = (props: UserInputPropsType): JSX.Element => {
    const [errormsg, setErrormsg] = React.useState('');

    return (
        <DynamicAutoComplete
            value={props.users}
            errormsg={errormsg}
            getOptions={async (query: string): Promise<UserInfoType[]> => {
                setErrormsg('');
                if (query) {
                    const result = await props.onQueryUsers(query, 20);

                    setErrormsg(result.errormsg || '');
                    return result.matches;
                }

                return [];
            }}
            getOptionLabel={(option): string => (typeof option === 'string' ? option : option.name || option.uuid + '')}
            renderOption={(props, option) => (
                <li key={option.uuid} {...props}>
                    <Typography variant="body1" display="inline">
                        {option.name}
                    </Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Typography variant="body2" display="inline" color="secondary" style={{ fontStyle: 'italic' }}>
                        UUID: {option.uuid}
                    </Typography>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Typography variant="body2" display="inline" color="textSecondary" style={{ fontStyle: 'italic' }}>
                        ({option.firm})
                    </Typography>
                </li>
            )}
            renderTag={(value, idx, props) => {
                return (
                    <Tooltip key={idx} title={<span style={{ fontSize: 12 }}>{value.firm}</span>}>
                        <Chip label={value.name || value.uuid} {...props} />
                    </Tooltip>
                );
            }}
            filterOptions={(options) =>
                options.filter((option) => !props.users.some((user) => user.uuid === option.uuid))
            }
            onChange={(value) => {
                if (props.onChange) {
                    props.onChange(value);
                }
            }}
        />
    );
};

export default UserInput;
