import React, { useEffect } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

/* Usage:

    <TabScroller
        activeTab={activeTab}
        onActiveTabChanged={setActiveTab}
        content={[
            {
                label: 'Label 1',
                content: <div>Content 1</div>,
            },
            {
                label: 'Label 2',
                content: <div>Content 2</div>,
            },
        ]}
    />
*/

const CONTENT_ELEM_PREFIX = 'tabScroller_content';


type TabScrollerProps = {
    content?: { label: string | React.ReactElement; content: JSX.Element }[];
    fixedRows?: number;
    activeTab?: number;
    onActiveTabChanged?: (activeTab: number) => void;
};

const TabScroller = (props: TabScrollerProps): JSX.Element => {
    const contentRef = React.createRef<HTMLDivElement>();

    useEffect(() => {
        if (contentRef?.current) {
            const elem = contentRef.current.querySelector(`div[id="${CONTENT_ELEM_PREFIX}_${props.activeTab || 0}"]`);
            if (elem) {
                elem.scrollIntoView(/* { behavior: 'smooth' } */); // 'smooth' does not seem to be working reliably in our setup
            }
        }
    }, [props.activeTab]);

    const tabs: JSX.Element[] = [];
    const fixedContent: JSX.Element[] = [];
    const scrollableContent: JSX.Element[] = [];

    (props.content || []).forEach((data, idx) => {
        if (idx < (props.fixedRows || 0)) {
            fixedContent.push(<div key={idx}>{data.content}</div>);
        } else {
            const tabIdx = tabs.length;
            const id = `${CONTENT_ELEM_PREFIX}_${tabIdx}`;

            tabs.push(
                <Tab
                    key={idx}
                    // id={`verttab-${idx}`}
                    label={data.label}
                    style={{alignItems: 'baseline'}}

                    onClick={() => {
                        if (props.onActiveTabChanged) {
                            props.onActiveTabChanged(tabIdx);
                        }
                    }}
                />,
            );
            scrollableContent.push(
                <div key={idx} id={id}>
                    {data.content}
                </div>,
            );
        }
    });

    return (
        <Box flexGrow={1} overflow="hidden" height="100%">
            <Box display="flex" flexDirection="row" height="100%">
                <Box>
                    <Tabs orientation="vertical" textColor="primary" value={props.activeTab || 0}>
                        {tabs}
                    </Tabs>
                </Box>
                <Box flexGrow={1} flexDirection="column" display="flex" overflow="hidden">
                    {fixedContent}
                    <div ref={contentRef} style={{ flexGrow: 1, overflow: 'auto', height: '100%' }}>
                        {scrollableContent}
                    </div>
                </Box>
            </Box>
        </Box>
    );
};

export default TabScroller;
