import React, { useContext, useEffect, useState } from 'react';
import { LinkHandlerContext, parseXmlTable } from './hooks';
import JsonViewer from './JsonViewer';

export type ProvenanceContentViewerProps = {
    data: Record<string, any>;
};

const ProvenanceContentViewer = ({ data }: ProvenanceContentViewerProps): React.ReactElement => {
    const [rawContent, setRawContent] = useState('');
    const [tableData, setTableData] = useState<Record<string, any>[] | undefined>();
    const [columns, setColumns] = useState<string[]>([]);
    const [json, setJson] = useState<Record<string, any> | undefined>();
    const [selectedContentName, setSelectedContentName] = useState<string>(() => Object.keys(data)[0] || '');
    const [errorMsg, setErrorMsg] = useState('');
    const { onFetchContent } = useContext(LinkHandlerContext);
    useEffect(() => {
        (async (): Promise<void> => {
            try {
                const url = data[selectedContentName];
                const { content, headers } = await onFetchContent(url);
                console.debug({ content, headers });

                setErrorMsg('');
                setRawContent('');

                if (/xml/.test(headers['content-type'])) {
                    setJson(undefined);

                    const _tableData = parseXmlTable(content);
                    console.log(tableData);
                    if (_tableData.length) {
                        setColumns(Object.keys(_tableData[0]));
                        setTableData(_tableData);
                    } else {
                        setColumns([]);
                        setTableData(undefined);
                    }
                } else if (/application\/json/.test(headers['content-type']) && content.length) {
                    try {
                        setJson(JSON.parse(content));
                    } catch (e) {
                        console.warn(e);
                        setJson(undefined);
                    }
                } else {
                    if (typeof content !== 'string') {
                        setRawContent(JSON.stringify(content, null, 2));
                    } else {
                        setRawContent(content);
                    }
                }
            } catch (e) {
                console.error(e);
                setErrorMsg('Error loading content');
            }
        })();
    }, [selectedContentName, data]);

    return (
        <>
            <div className="data-viewer-label raw-content-chooser">
                {Object.keys(data).map((name, i) => (
                    <span
                        key={i}
                        className={(selectedContentName === name && 'selected') || ''}
                        onClick={(): void => setSelectedContentName(name)}
                    >
                        {name}
                    </span>
                ))}
            </div>

            <div className="provenance-content-container">
                {(errorMsg && <span className="error-text">{errorMsg}</span>) ||
                    (tableData && tableData.length && (
                        <table className="data-viewer-table">
                            <thead>
                                <tr>
                                    {columns.map((column: string) => (
                                        <td key={column}>{column}</td>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((row, i) => (
                                    <tr className="data-viewer-table-row" key={i}>
                                        {columns.map((column: string, j: number) => (
                                            <td key={j}>{row[column]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )) ||
                    (json && <JsonViewer data={json} />) || <div className="raw-content-viewer">{rawContent}</div>}
            </div>
        </>
    );
};

export default ProvenanceContentViewer;
