import React, { useState } from 'react';
import ViewerLabel from './ViewerLabel';
import { CTDatum } from './runTreeTypes';

export type ChangeDetectionViewerProps = {
    data: Record<string, any>;
    onSelectRow?: (index: number | undefined) => void;
};

const ChangeDetectionViewer = ({ data }: ChangeDetectionViewerProps): React.ReactElement => {
    const [hideHashes, setHideHashes] = useState(true);

    let columns = data;
    if (hideHashes) {
        columns = {};
        for (const [name, val] of Object.entries(data)) {
            if (name === 'isNew' || name === '__VALUE_HASH_INPUT__') {
                columns[name] = val;
            }
        }
    }

    const colNames = Object.keys(columns);
    if (!colNames.length) return <></>;
    const table = new Array(colNames.length);
    const rows = Object.values(columns)[0].values;
    for (let j = 0; j < rows.length; j++) {
        table[j] = {};
        colNames.forEach((name) => {
            const vals = data[name].values;
            table[j][name] = vals[j];
        });
    }

    const description = (
        <>
            <label style={{ display: 'inline-flex', alignItems: 'baseline' }}>
                <input
                    type="checkbox"
                    checked={hideHashes}
                    onChange={(): void => {
                        setHideHashes((b) => !b);
                    }}
                />
                <span>Hide hashes</span>
            </label>
        </>
    );
    return (
        <>
            <ViewerLabel name="ChangeDetection" description={description} />
            <div className="provenance-content-container">
                <table className="data-viewer-table">
                    <thead>
                        <tr>
                            {colNames.map((column) => (
                                <td key={column}>{column}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {table.map((row: Record<string, string>, i) => (
                            <tr
                                className="data-viewer-table-row"
                                key={i}
                                style={{
                                    textAlign: 'start',
                                    borderBottom: '1px solid white',
                                    color: row.isNew === 'true' ? '#99ffc0' : '#fff',
                                }}
                            >
                                {Object.entries(row).map((entry, i: number) => (
                                    <td key={i}>{entry[1]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
};

export default ChangeDetectionViewer;
