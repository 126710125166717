import {
    AlysAlertEmailNotificationConfig,
    AlysAlertNotificationConfig,
    AlysAlertNotificationOccurrenceConfig,
    AlysAlertNotificationType,
    AlysAlertCondition,
} from '@webacq/wa-shared-definitions';
import { AlysAlertConfigData } from '@webacq/wa-shared-servicewrappers';
import {
    AlertNotificationOccurrenceType,
    AlertNotificationRangeType,
    AlertNotificationRangeUnitType,
    AlertNotificationType,
    AlertOptionType,
} from './types';

export const CustomAlertOption: AlertOptionType = {
    id: '0',
    name: 'Customize',
    description: 'Customize the alert based on your needs',
};

export const AlertTemplates: AlertOptionType[] = [
    {
        id: '46387b46-14c6-4ad1-847e-3ebde3bdf029',
        name: 'Profiles default alert',
        description: 'Default template for Profile Jobs',
    },
    {
        id: '1372c9a0-853b-48cb-893d-0411b3d5f7a6',
        name: 'ArticleX Rank 4 Alert Template',
        description: 'Default template for ArticleX Rank 4 Jobs',
    },
    {
        id: 'ff2d1e36-b602-4e8f-b945-f704de690584',
        name: 'ArticleX Rank 5 Alert Template',
        description: 'Default template for ArticleX Rank 5 Jobs',
    },
    {
        id: '03ffdf09-7e50-4833-bb10-921af7df2a1a',
        name: 'Private Equity Alert Template',
        description: 'Default template for Private Equity Jobs',
    },    
];

export const pluralSuffix = (count?: number): string => (count !== 1 ? 's' : '');

export const maxAlertOptionNameLength = (alertOptions: AlertOptionType[]): number =>
    Math.max(CustomAlertOption.name.length, ...alertOptions.map((option) => option.name.length));

export class AlertConfigurationData {
    private _alertConfigId: string;
    private _isTemplate: boolean;
    private _conditions: AlysAlertCondition[];
    private _occurrenceConfig: AlertNotificationOccurrenceType;
    private _notifications: AlertNotificationType;

    constructor() {
        this._alertConfigId = '';
        this._isTemplate = false;
        this._conditions = [];
        this._occurrenceConfig = {
            numOccurrences: 1,
            observationWindow: {
                enabled: true,
                data: {
                    length: 1,
                    unit: AlertNotificationRangeUnitType.DAYS,
                },
            },
        };
        this._notifications = {
            createWorkItem: true,
            sendMsgSettings: {
                enabled: false,
                data: {
                    frequency: {
                        length: 365,
                        unit: AlertNotificationRangeUnitType.DAYS,
                    },
                    recipients: [],
                },
            },
        };
    }

    get alertConfigId(): string {
        return this._alertConfigId;
    }

    set alertConfigId(alertConfigId: string) {
        this._alertConfigId = alertConfigId;
    }

    get isTemplate(): boolean {
        return this._isTemplate;
    }

    set isTemplate(isTemplate: boolean) {
        this._isTemplate = isTemplate;
    }

    get conditions(): AlysAlertCondition[] {
        return this._conditions;
    }

    set conditions(conditions: AlysAlertCondition[]) {
        this._conditions = conditions;
    }

    get occurrenceConfig(): AlertNotificationOccurrenceType {
        return this._occurrenceConfig;
    }

    set occurrenceConfig(occurrenceConfig: AlertNotificationOccurrenceType) {
        this._occurrenceConfig = occurrenceConfig;
    }

    get notifications(): AlertNotificationType {
        return this._notifications;
    }

    set notifications(notifications: AlertNotificationType) {
        this._notifications = notifications;
    }

    public isValid(): boolean {
        return true;
    }

    private static _timeRangeToMinutes(range: AlertNotificationRangeType): number {
        if (range.length) {
            switch (range.unit) {
                case AlertNotificationRangeUnitType.MINUTES:
                    return range.length;

                case AlertNotificationRangeUnitType.HOURS:
                    return range.length * 60;

                case AlertNotificationRangeUnitType.DAYS:
                    return range.length * 60 * 24;
            }
        }

        return 0;
    }

    private static _minutesToTimeRange(minutes: number): AlertNotificationRangeType {
        const minsPerDay = 60 * 24;

        const days = Math.floor(minutes / minsPerDay);
        if (days * minsPerDay === minutes) {
            return {
                length: days,
                unit: AlertNotificationRangeUnitType.DAYS,
            };
        }

        const minsPerHour = 60;

        const hours = Math.floor(minutes / minsPerHour);
        if (hours * minsPerHour === minutes) {
            return {
                length: hours,
                unit: AlertNotificationRangeUnitType.HOURS,
            };
        }

        return {
            length: minutes,
            unit: AlertNotificationRangeUnitType.MINUTES,
        };
    }

    public toAlysAlertConfigData(): AlysAlertConfigData {
        const occurrenceConfig: AlysAlertNotificationOccurrenceConfig = {
            numOccurrences: this.occurrenceConfig.numOccurrences || 0,
        };
        if (this.occurrenceConfig.observationWindow.enabled) {
            const owData = this.occurrenceConfig.observationWindow.data;

            switch (owData.unit) {
                case AlertNotificationRangeUnitType.RUNS:
                case AlertNotificationRangeUnitType.TIMES:
                    occurrenceConfig.observationWindowNumRuns = owData.length;
                    break;

                default:
                    occurrenceConfig.observationWindowMinutes = AlertConfigurationData._timeRangeToMinutes(owData);
                    break;
            }
        }

        const notifications: AlysAlertNotificationConfig[] = [];
        if (this.notifications.createWorkItem) {
            notifications.push({
                type: AlysAlertNotificationType.WORKFLOW_NOTIFICATION_CONFIG,
            });
        }
        if (this.notifications.sendMsgSettings.enabled) {
            notifications.push({
                type: AlysAlertNotificationType.EMAIL_NOTIFICATION_CONFIG,
                data: this.notifications.sendMsgSettings.data.recipients.map((recipient) => recipient.uuid),
                minutesPerNotification: AlertConfigurationData._timeRangeToMinutes(
                    this.notifications.sendMsgSettings.data.frequency,
                ),
            });
        }

        return {
            alertConfig: {
                id: this.alertConfigId || '',
                isTemplate: this.isTemplate,
            },
            conditions: this.conditions,
            occurrenceConfig: occurrenceConfig,
            notifications: notifications,
        };
    }

    public static fromAlysAlertConfigData(alertConfigData: AlysAlertConfigData): AlertConfigurationData {
        const alertConfig = new AlertConfigurationData();

        alertConfig.alertConfigId = alertConfigData.alertConfig.id;
        alertConfig.isTemplate = !!alertConfigData.alertConfig.isTemplate;

        alertConfig.conditions = alertConfigData.conditions
            .filter((condition) => condition.metric)
            .map((condition) => ({
                metric: condition.metric,
                operator: condition.operator,
                operands: (condition.operands || []).map((operand) => (operand ? parseInt(operand + '') : 0)),
            }));

        alertConfig.notifications.createWorkItem = false;
        alertConfig.notifications.sendMsgSettings.enabled = false;

        if (alertConfigData.occurrenceConfig) {
            alertConfig.occurrenceConfig.numOccurrences = alertConfigData.occurrenceConfig.numOccurrences;

            if (alertConfigData.occurrenceConfig.observationWindowNumRuns) {
                alertConfig.occurrenceConfig.observationWindow.enabled = true;
                alertConfig.occurrenceConfig.observationWindow.data = {
                    length: alertConfigData.occurrenceConfig.observationWindowNumRuns,
                    unit: AlertNotificationRangeUnitType.RUNS,
                };
            } else if (alertConfigData.occurrenceConfig.observationWindowMinutes) {
                alertConfig.occurrenceConfig.observationWindow.enabled = true;
                alertConfig.occurrenceConfig.observationWindow.data = AlertConfigurationData._minutesToTimeRange(
                    alertConfigData.occurrenceConfig.observationWindowMinutes,
                );
            } else {
                alertConfig.occurrenceConfig.observationWindow.enabled = false;
            }
        }

        alertConfigData.notifications.forEach((notification) => {
            switch (notification.type) {
                case AlysAlertNotificationType.WORKFLOW_NOTIFICATION_CONFIG:
                    alertConfig.notifications.createWorkItem = true;
                    break;

                case AlysAlertNotificationType.EMAIL_NOTIFICATION_CONFIG: {
                    const msgNotification = notification as AlysAlertEmailNotificationConfig;

                    alertConfig.notifications.sendMsgSettings.enabled = true;
                    alertConfig.notifications.sendMsgSettings.data.recipients = msgNotification.data.map(
                        (recipient) => ({
                            uuid: recipient,
                        }),
                    );

                    const minsPerNotification = msgNotification.minutesPerNotification;

                    if (minsPerNotification) {
                        alertConfig.notifications.sendMsgSettings.data.frequency =
                            AlertConfigurationData._minutesToTimeRange(minsPerNotification);
                    }

                    break;
                }
            }
        });

        return alertConfig;
    }

    public clone(): AlertConfigurationData {
        const alertConfig = new AlertConfigurationData();

        alertConfig.alertConfigId = this.alertConfigId;
        alertConfig.isTemplate = this.isTemplate;
        alertConfig.conditions = [...this.conditions];
        alertConfig.occurrenceConfig = { ...this.occurrenceConfig };
        alertConfig.notifications = { ...this.notifications };

        return alertConfig;
    }
}

export class AlertConfigurationStateData {
    config: AlertConfigurationData;
    modified: boolean;
    collapsed: boolean;
    useTransition: boolean;

    constructor() {
        this.config = new AlertConfigurationData();
        this.modified = false;
        this.collapsed = false;
        this.useTransition = false;
    }

    public clone(): AlertConfigurationStateData {
        const data = new AlertConfigurationStateData();

        data.config = this.config.clone();
        data.modified = this.modified;
        data.collapsed = this.collapsed;
        data.useTransition = this.useTransition;

        return data;
    }
}
