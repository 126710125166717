import React, { useContext } from 'react';
import clsx from 'clsx';
import { constructGeckoTree, useSelectedNode, SelectionApiContext } from './hooks';
import ArrowBoard from './ArrowBoard';
import { CTDatum, RawNodeDatum } from './runTreeTypes';

const GeckoNode = ({ node }: { node: RawNodeDatum }) => {
    const selected = useSelectedNode();
    const { onHighlightNode, clearHighlight } = useContext(SelectionApiContext);
    const isSelected = (selected?.selectedNode as CTDatum)?.geckoNodeId === node.name;
    let label = <></>;

    switch (node.attributes?.nodeType) {
        case 'navigation':
            label = <b>Navigation</b>;
            break;
        case 'extraction':
            label = <b>Extraction</b>;
            break;
        case 'delivery':
            label = <b>Delivery</b>;
            break;
        case 'changeDetection':
            label = <b>Change Detection</b>;
            break;
    }

    return (
        <div className="gecko-node">
            <div
                id={`graph-node-${node.name}`}
                onMouseEnter={(): void => {
                    onHighlightNode('geckoNodeId', node.name);
                }}
                onMouseLeave={() => {
                    onHighlightNode('geckoNodeId', '');
                }}
                className={clsx('gecko-node-label', {
                    'selected-gecko-node': isSelected,
                })}
            >
                {label}
                <br />
                <small>{node.name}</small>
            </div>

            {node.children?.map((child: RawNodeDatum) => (
                <GeckoNode node={child} key={child.name} />
            ))}
        </div>
    );
};

export type GeckoTreeProps = {
    geckoGraph: Record<string, any>;
};

const GeckoTree = ({ geckoGraph }: GeckoTreeProps): JSX.Element => {
    const tree = React.useMemo(() => constructGeckoTree(geckoGraph), [geckoGraph]);

    return (
        <div style={{ position: 'relative', marginLeft: -12 }}>
            <ArrowBoard data={tree as RawNodeDatum} />
            <GeckoNode node={tree as RawNodeDatum} />
        </div>
    );
};

export default GeckoTree;
