import React, { useState, useEffect, useMemo, createContext } from 'react';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { GeckoContextTree, GeckoDeliveries } from '@webacq/wa-shared-definitions';
import GeckoTree from './GeckoTree';
import { useConstructOrgChartData, SelectionApiContext, SelectedDataContext, LinkHandlerContext, defaultLinkHandlerApi } from './hooks';
import {
    SelectedData,
    CTDatum,
    IProvenanceRecord,
    IProvenanceNode,
    IProvenanceTreeNode,
    IProvenanceContentNode,
    FetchContentHandler,
} from './runTreeTypes';
import NavigationViewer from './NavigationViewer';
import ProvenanceNodeViewer from './ProvenanceNodeViewer';
import ProvenanceContentViewer from './ProvenanceContentViewer';
import ExtractionViewer from './ExtractionViewer';
import ChangeDetectionViewer from './ChangeDetectionViewer';
import OrgChart from './ContextTree';
import ProvenanceTree from './ProvenanceTree';
import DeliveryViewer from './DeliveryViewer';
import Timeline from './Timeline';
import 'react-reflex/styles.css';
import './tree.css';

export type TreeProps = {
    contextTree: GeckoContextTree;
    geckoGraph: Record<string, any>;
    provenanceRecords?: Array<IProvenanceRecord>;
    onOpenUrl?: (url: string) => void;
    getBcosEndpoint?: (url: string) => string;
    onFetchContent: FetchContentHandler;
    onRefreshProvenance?: () => void;
    hubUrl?: string;
};
const RunTree = ({
    contextTree,
    geckoGraph,
    provenanceRecords,
    onOpenUrl,
    getBcosEndpoint,
    onFetchContent,
    onRefreshProvenance,
    hubUrl,
}: TreeProps): React.ReactElement => {
    const { treeMap } = useConstructOrgChartData(contextTree as unknown as GeckoContextTree, geckoGraph.gecko);
    const [geckoTreeFlex, setGeckoTreeFlex] = useState(0.5);
    const [timelineFlex, setTimelineFlex] = useState(0.5);
    const INITIAL_SIDEBAR_FLEX = 0.2;
    const [sidebarFlex, setSidebarFlex] = useState(INITIAL_SIDEBAR_FLEX);
    const [selectedData, setSelectedData] = useState<SelectedData>({
        selectedNode: undefined,
        highlightedNodes: [],
    });
    const [rootStepDeliveryUuid, setRootStepDeliveryUuid] = useState<string>();

    const handleSelectExtractionRow = (index: number | undefined): void => {
        // if (selectedData.type === 'extraction' && index !== undefined) {
        //     setSelectedData((selected) => ({ ...selected, extractionRow: index }));
        // } else {
        //     setSelectedData((selected) => ({ ...selected, extractionRow: undefined }));
        // }
    };

    const findDeliveryUuidForBranch = (node: CTDatum | IProvenanceNode | undefined): string | undefined => {
        if (node && 'geckoNodeId' in node && node.geckoNodeId) {
            if (node.type === 'delivery') {
                return node.uuid.replace('delivery__', '');
            } else {
                let deliveryUuid;
                for (const childNodeId of node.children) {
                    if (typeof childNodeId === 'string') {
                        const node = treeMap.get(childNodeId);
                        if (node) {
                            const tempUuid = findDeliveryUuidForBranch(node);
                            if (tempUuid) {
                                if (deliveryUuid) {
                                    return undefined;
                                }
                                deliveryUuid = tempUuid;
                            }
                        }
                    }
                }
                return deliveryUuid;
            }
        }

        return undefined;
    };

    const getSelectionApi = useMemo(() => {
        return {
            onSelectNode: (node: CTDatum | IProvenanceNode | undefined): void => {
                setSelectedData((data) => ({
                    ...data,
                    selectedNode: node,
                }));

                setRootStepDeliveryUuid(findDeliveryUuidForBranch(node));
            },
            onHighlightNode: (property: keyof CTDatum, val: string) => {
                const nodes = [...treeMap.values()].filter((node: CTDatum) => {
                    return property in node && !!val && node[property] === val;
                });
                setSelectedData((data) => ({
                    ...data,
                    highlightedNodes: nodes.map((n) => n.uuid),
                }));
            },
            clearHighlight: (uuid: string) => {
                setSelectedData((data) => ({
                    ...data,
                    highlightedNodes: data.highlightedNodes.filter((id) => uuid !== id),
                }));
            },
        };
    }, []);

    const openBcosViewer = (url: string) => {
        const open = onOpenUrl || defaultLinkHandlerApi.onOpenUrl;
        open(`${hubUrl || defaultLinkHandlerApi.hubUrl}/object?url=${window.encodeURIComponent(url)}`);
    };
    const linkApi = {
        getBcosEndpoint: getBcosEndpoint || defaultLinkHandlerApi.getBcosEndpoint,
        openBcosViewer: openBcosViewer || defaultLinkHandlerApi.openBcosViewer,
        onOpenUrl: onOpenUrl || defaultLinkHandlerApi.onOpenUrl,
        onFetchContent: onFetchContent || defaultLinkHandlerApi.onFetchContent,
        hubUrl: hubUrl || defaultLinkHandlerApi.hubUrl,
    };

    useEffect(() => {
        if (selectedData.selectedNode && geckoTreeFlex === 0.5) {
            setGeckoTreeFlex(0.2);
            setTimelineFlex(0.2);
        }

        if (selectedData.selectedNode && sidebarFlex === INITIAL_SIDEBAR_FLEX) {
            setSidebarFlex(0.3);
        }
    }, [selectedData.selectedNode]);

    const handleRefreshProv = () => {
        if (typeof onRefreshProvenance === 'function') {
            onRefreshProvenance();
        }
    };
    if (!contextTree) return <></>;
    const selectedNode = selectedData?.selectedNode;
    return (
        <SelectionApiContext.Provider value={getSelectionApi}>
            <SelectedDataContext.Provider value={selectedData}>
                <LinkHandlerContext.Provider value={linkApi}>
                    <ReflexContainer orientation="vertical">
                        <ReflexElement flex={sidebarFlex}>
                            <ReflexContainer orientation="horizontal">
                                <ReflexElement className="graph-container" flex={geckoTreeFlex}>
                                    <GeckoTree geckoGraph={geckoGraph.gecko} />
                                </ReflexElement>

                                <ReflexSplitter />

                                <ReflexElement className="graph-container" flex={timelineFlex} minSize={70}>
                                    <Timeline
                                        geckoGraph={geckoGraph.gecko}
                                        contextTree={contextTree}
                                        contextTreeMap={treeMap}
                                    />
                                </ReflexElement>

                                <ReflexSplitter />

                                <ReflexElement className="data-viewer-container">
                                    {selectedNode?.type && (
                                        <>
                                            {selectedNode.type === 'extraction' && (
                                                <ExtractionViewer
                                                    data={(selectedNode.body || {}) as Record<string, any>}
                                                    onSelectRow={handleSelectExtractionRow}
                                                />
                                            )}
                                            {selectedNode.type === 'changeDetection' && (
                                                <ChangeDetectionViewer
                                                    data={(selectedNode.body || {}) as Record<string, any>}
                                                    onSelectRow={handleSelectExtractionRow}
                                                />
                                            )}
                                            {(selectedNode.type === 'navigation' || selectedNode.type === 'start') && (
                                                <NavigationViewer node={selectedNode} onOpenUrl={onOpenUrl} />
                                            )}
                                            {selectedNode.type === 'delivery' && (
                                                <DeliveryViewer
                                                    delivery={selectedNode.body as GeckoDeliveries}
                                                    baseUri={selectedNode.url}
                                                />
                                            )}
                                            {selectedNode.type === 'provenance' && (
                                                <ProvenanceNodeViewer node={selectedNode as IProvenanceTreeNode} />
                                            )}
                                            {selectedNode.type === 'provenance-content' && (
                                                <ProvenanceContentViewer data={(selectedNode as IProvenanceContentNode).data} />
                                            )}
                                        </>
                                    )}
                                </ReflexElement>
                            </ReflexContainer>
                        </ReflexElement>

                        <ReflexSplitter />

                        <ReflexElement>
                            <ReflexContainer orientation="horizontal">
                                <ReflexElement className="run-tree-container" flex={0.5}>
                                    <OrgChart
                                        contextTreeMap={treeMap}
                                        rootNodeId={contextTree.uuid}
                                    />
                                </ReflexElement>

                                <ReflexSplitter />

                                <ReflexElement>
                                    {(provenanceRecords?.length && (
                                        <ProvenanceTree
                                            records={provenanceRecords}
                                            onRefresh={handleRefreshProv}
                                            rootStepDeliveryUuid={rootStepDeliveryUuid}
                                        />
                                    )) || (
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <div>
                                                <h4>No provenance records</h4>
                                                <a style={{ cursor: 'pointer' }} onClick={handleRefreshProv}>
                                                    🔄 Refresh
                                                </a>
                                            </div>
                                        </div>
                                    )}
                                </ReflexElement>
                            </ReflexContainer>
                        </ReflexElement>
                    </ReflexContainer>
                </LinkHandlerContext.Provider>
            </SelectedDataContext.Provider>
        </SelectionApiContext.Provider>
    );
};
export default RunTree;
