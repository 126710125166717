/* eslint-disable no-loop-func */
/**
 *  NOTE: this is an experimental compontent
 */

import React, { useLayoutEffect, useRef } from 'react';
import { getGraphRelationships } from './hooks';
import { ArrowRelationshipType, RawNodeDatum } from './runTreeTypes';

type ArrowBoardType = {
    data: RawNodeDatum;
    idPrefix?: string
};

const ArrowBoard = ({ data, idPrefix = '' }: ArrowBoardType): React.ReactElement => {
    const containerRef = useRef<HTMLDivElement>(null);
    const svgRef = useRef<SVGSVGElement>(null);

    useLayoutEffect(() => {
        // Some snippets borrowed by https://jsfiddle.net/bbcfk164/
        const redrawArrows = (): void => {
            const signum = (x: number): number => (x < 0 ? -1 : 1);

            const absolute = (x: number): number => (x < 0 ? -x : x);

            function setPathLine(
                path: SVGPathElement,
                startX: number,
                startY: number,
                endX: number,
                endY: number,
                type?: string,
            ): SVGPathElement {
                const deltaX = (endX - startX) * 0.15;
                const deltaY = (endY - startY) * 0.15;

                // for further calculations which ever is the shortest distance
                const delta = deltaY < absolute(deltaX) ? deltaY : absolute(deltaX);

                // set sweep-flag (counter/clock-wise)
                // if start element is closer to the left edge,
                // draw the first arc counter-clockwise, and the second one clock-wise
                let arc1 = 0;
                let arc2 = 1;
                if (startX > endX) {
                    arc1 = 1;
                    arc2 = 0;
                }

                if (type === 'children') {
                    path.setAttribute('d', `M${startX} ${startY} V${endY + 16} H${endX - 9 - delta * signum(deltaX)}`);
                } else {
                    // 1. move a bit down, 2. arch,  3. move a bit to the right, 4.arch, 5. move down to the end
                    path.setAttribute(
                        'd',
                        `M${startX} ${startY} V${startY + delta} A${delta} ${delta} 0 0 ${arc1} ${
                            startX + delta * signum(deltaX)
                        } ${startY + 2 * delta} H${
                            endX - delta * signum(deltaX)
                        } A${delta} ${delta} 0 0 ${arc2} ${endX} ${startY + 3 * delta} V${endY}`,
                    );
                }
                return path;
            }

            function connectElements(
                path: SVGPathElement,
                startElem: HTMLElement,
                endElem: HTMLElement,
                type?: string,
            ): void {
                const svgContainer = containerRef.current;
                if (!svgContainer) return;

                const svgContainerRect = svgContainer.getBoundingClientRect();
                const svgContainerOffset = {
                    top: svgContainerRect.top + document.body.scrollTop,
                    left: svgContainerRect.left + document.body.scrollLeft,
                };
                const MARGIN_LEFT = 12;

                const startElemRect = startElem.getBoundingClientRect();
                const endElemRect = endElem.getBoundingClientRect();
                const startCoord = {
                    top: startElemRect.top,
                    left: startElemRect.left - svgContainerOffset.left + MARGIN_LEFT,
                };
                const endCoord = {
                    top: endElemRect.top + document.body.scrollTop,
                    left: endElemRect.left - svgContainerOffset.left + MARGIN_LEFT,
                };
                const svgTop = svgContainerOffset.top;

                const startX = startCoord.left;
                const startY = startCoord.top + startElem.offsetHeight - svgTop;

                const endX = endCoord.left;
                const endY = endCoord.top - svgTop - 4;
                const isStraightLine = startX === endX || startY === endY;
                // #gradient1 has a smoother transition and uses gradientUnits="boundingBox",
                // but this won't work for a straight line which must use #gradient2
                // because it has gradientUnits="userSpaceOnUse".
                const gradient = isStraightLine ? 'gradient2' : 'gradient1';

                path.setAttribute('stroke', `url(#${gradient})`);

                setPathLine(path, startX, startY, endX, endY, type);
            }

            const createPath = (id?: string): SVGPathElement => {
                const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
                path.setAttribute('d', 'M0 0');
                path.setAttribute('marker-end', 'url(#arrowhead)');
                if (id) {
                    path.setAttribute('id', id);
                }
                return path;
            };
            const createGroup = (id: string): SVGGElement => {
                const g = document.createElementNS('http://www.w3.org/2000/svg', 'g');
                g.setAttribute('id', id);
                g.setAttribute('stroke', 'url(#gradient1)');
                g.setAttribute('fill', 'none');
                g.setAttribute('gradient-units', 'userSpaceOnUse');
                g.setAttribute('stroke-width', '1');
                return g;
            };

            let pathMap = new Map();
            function drawArrow(rel: ArrowRelationshipType): void {
                const start = document.getElementById(rel.source) as HTMLElement;
                const end = document.getElementById(rel.sink) as HTMLElement;
                const pathId = `${idPrefix}${rel.source}-${rel.sink}`;
                const path = createPath();
                // console.debug({ start, end, path });
                try {
                    if (path && start && end) {
                        connectElements(path, start, end, rel.relType);
                        if (!pathMap.has(pathId)) {
                            pathMap.set(pathId, path);
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            const svgElem = svgRef.current;

            const cleanup = (): void => {
                pathMap = new Map();

                const groupElem = svgElem?.getElementById('path-group');
                if (svgElem && groupElem) {
                    svgElem.removeChild(groupElem);
                    const g = createGroup('path-group');
                    svgElem.appendChild(g);
                }
            };

            const drawPaths = (): void => {
                const rels = getGraphRelationships(data);
                rels.forEach(drawArrow);
                const gElem = svgElem?.getElementById('path-group');
                if (gElem) {
                    pathMap.forEach((p) => {
                        try {
                            gElem.appendChild(p);
                        } catch (e) {
                            console.error(e);
                        }
                    });
                }
            };
            cleanup();
            drawPaths();
        };

        let timeout = 0;
        const debounced = (): void => {
            if (timeout) {
                clearTimeout(timeout);
            }

            timeout = window.setTimeout(() => {
                timeout = 0;
                redrawArrows();
            }, 50);
        };

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /* @ts-ignore-nextline */
        // https://drafts.csswg.org/resize-observer-1/
        const resizeObserver = new window.ResizeObserver(debounced);
        resizeObserver.observe(containerRef.current as Element);
        return (): void => {
            resizeObserver.disconnect();
        };
    }, [data]);

    return (
        <div
            id="svgContainer"
            ref={containerRef}
            style={{
                position: 'absolute',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
            }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" id="arrow-board" ref={svgRef}>
                <defs>
                    <marker
                        id="arrowhead"
                        viewBox="0 0 10 10"
                        refX="3"
                        refY="5"
                        markerWidth="6"
                        markerHeight="6"
                        orient="auto"
                    >
                        <path d="M 0 0 L 10 5 L 0 10 z" fill="rgb(0, 255, 255)" />
                    </marker>
                    <linearGradient id="gradient1" x1="0%" y1="0%" x2="50%" y2="50%" gradientUnits="objectBoundingBox">
                        <stop offset="0%" stopColor="rgb(213, 253, 103)" />
                        <stop offset="70%" stopColor="rgb(0, 255, 255)" />
                    </linearGradient>

                    <linearGradient id="gradient2" x1="0%" y1="0%" x2="50%" y2="50%" gradientUnits="userSpaceOnUse">
                        <stop offset="0%" stopColor="rgb(213, 253, 103)" />
                        <stop offset="70%" stopColor="rgb(0, 255, 255)" />
                    </linearGradient>
                </defs>
                <g
                    id="path-group"
                    fill="url(#gradient1)"
                    stroke="url(#gradient1)"
                    strokeWidth="1"
                    markerEnd="url(#arrowhead)"
                />
            </svg>
        </div>
    );
};

export default React.memo(ArrowBoard);
