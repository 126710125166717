import React, { useContext } from 'react';
import clsx from 'clsx';
import { useSelectedNode, SelectionApiContext, isValidUrl, LinkHandlerContext } from './hooks';
import { IProvenanceContentNode, IProvenanceNode } from './runTreeTypes';

export type ProvenanceNodeProps = {
    node: IProvenanceNode;
    isActive: boolean;
};

const gatherUrls = (obj: Record<string, unknown> = {}) =>
    Object.entries(obj).filter(([name, val]: [string, string]) => (
        isValidUrl(window.encodeURI(val as string)) && /(blpprofessional|bloomberg)/.test(val))
    );

const ProvenanceNode = ({ isActive, node }: ProvenanceNodeProps): JSX.Element => {
    const selection = useSelectedNode();
    const mainNodeSelected = selection?.selectedNode?.uuid === node.uuid;
    const contentNodeSelected = selection?.selectedNode?.uuid === `${node.uuid}__content`;
    const { onSelectNode } = useContext(SelectionApiContext);
    const { openBcosViewer } = useContext(LinkHandlerContext);
    const statusClass = 'provenance-status-' + node.data.eventStatus?.toLowerCase() ?? 'undefined';
    const eventData = node.data.eventData ?? {};
    let contentUrls = gatherUrls(eventData);

    // These provenance records have an unstable schema and have changed formats often.
    // Try as best we could to parse the eventData.
    if (!contentUrls.length && eventData['dfr.summary']) {
        let summaryObject = eventData['dfr.summary'];
        if (typeof summaryObject === 'string') {
            try {
                summaryObject = JSON.parse(eventData['dfr.summary']);
                contentUrls = gatherUrls(summaryObject);
            } catch (e) {
                // console.warn(e);
            }
        } else {
            contentUrls = gatherUrls(summaryObject);
        }
    }
    const urlData = Object.fromEntries(contentUrls);

    return (
        <>
            <div className="context-tree-node-wrapper" id={node.uuid}>
                <div
                    className={clsx('context-tree-node-base', 'provenance-node', {
                        'selected-node': mainNodeSelected,
                        'faded-node': !isActive,
                    })}
                    onClick={(e) => {
                        onSelectNode(node);
                        e.stopPropagation();
                    }}
                >
                    <span className="node-title">{node.data.agentName}</span>

                    {node.data.eventName && node.data.agentName !== node.data.eventName && (
                        <i>&nbsp;{node.data.eventName}</i>
                    )}
                    <br />
                    <span className={statusClass}>{node.data.eventStatus}</span>
                    <br />
                    <span>{node.data.recordTime}</span>
                </div>
            </div>

            {!!contentUrls.length && (
                <>
                    <div
                        className={clsx('context-tree-node-base', 'provenance-content-node', {
                            'selected-node': contentNodeSelected,
                        })}
                        onClick={(e) => {
                            onSelectNode({
                                ...node,
                                uuid: `${node.uuid}__content`,
                                type: 'provenance-content',
                                data: urlData,
                            } as IProvenanceContentNode);
                            e.stopPropagation();
                        }}
                    >
                        <div className={clsx('provenance-node-triangle', {})} />
                        <b>CONTENT</b>
                        <br />
                        {contentUrls.map(([name, url]) => (
                            <div key={name}>
                                <a
                                    onClick={(e) => {
                                        openBcosViewer(url as string);
                                        e.stopPropagation();
                                    }}
                                >
                                    {name} &#10697;
                                </a>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </>
    );
};

export default ProvenanceNode;
