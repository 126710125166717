import React from 'react';
import { Typography, Paper, Collapse, IconButton, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExpandMore } from '@mui/icons-material';

const useStyles = makeStyles((theme: Theme) => ({
    paddedBox: {
        padding: theme.spacing(1),
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

type Qualifiers = {
    ctrl: boolean;
    alt: boolean;
    shift: boolean;
};

type CollapsibleCardProps = {
    collapsed: boolean;
    onCollapsedChanged?: (collapsed: boolean, qualifiers: Qualifiers) => void;
    title?: string | React.ReactElement;
    children?: React.ReactNode;
    square?: boolean;
    unmountOnExit?: boolean;
};

const CollapsibleCard = (props: CollapsibleCardProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Paper elevation={1} className={classes.paddedBox} square={props.square}>
            <Box display="flex" flexDirection="row" alignItems="center">
                <IconButton
                    className={`${classes.expand}${props.collapsed ? '' : ' ' + classes.expandOpen}`}
                    onClick={(event): void => {
                        if (props.onCollapsedChanged) {
                            const qualifiers: Qualifiers = {
                                ctrl: event.ctrlKey,
                                alt: event.altKey,
                                shift: event.shiftKey,
                            };

                            props.onCollapsedChanged(!props.collapsed, qualifiers);
                        }
                    }}
                    size="large">
                    <ExpandMore />
                </IconButton>
                {React.isValidElement(props.title) ? (
                    props.title
                ) : (
                    <Typography variant="h6" color="textPrimary">
                        {props.title}
                    </Typography>
                )}
            </Box>

            <Collapse in={!props.collapsed} timeout="auto" unmountOnExit={props.unmountOnExit}>
                {props.children}
            </Collapse>
        </Paper>
    );
};

export default CollapsibleCard;
