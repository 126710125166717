import React, { useContext } from 'react';
import clsx from 'clsx';
import { LinkHandlerContext, SelectionApiContext, useSelectedNode } from './hooks';
import { CTDatum } from './runTreeTypes';
import { GeckoDeliveries, GeckoRawDeliveryLocationBcos } from '@webacq/wa-shared-definitions';

export type DeliveryNodeProps = {
    node: CTDatum;
};

const DeliveryNode = ({ node }: DeliveryNodeProps): JSX.Element => {
    const delivery = node.body as GeckoDeliveries;
    const selection = useSelectedNode();
    const isSelected = selection?.selectedNode?.uuid === node.uuid;
    const isHighlighted = selection?.highlightedNodes.includes(node.uuid);
    const { onSelectNode, onHighlightNode, clearHighlight } = useContext(SelectionApiContext);

    const { openBcosViewer } = useContext(LinkHandlerContext);

    const handleOpenUrl = (): void => {
        const url = (delivery.raw.location as GeckoRawDeliveryLocationBcos).url;
        if (url) {
            openBcosViewer(url);
        }
    };

    return (
        <div className="context-tree-node-wrapper">
            <div
                className={clsx('context-tree-node-base', 'delivery-node', {
                    'selected-node': isSelected,
                    'highlighted-node': isHighlighted,
                })}
                onClick={(e): void => {
                    onSelectNode(node);
                    e.stopPropagation();
                }}
                onMouseEnter={() => {
                    onHighlightNode('uuid', node.uuid);
                }}
                onMouseLeave={() => {
                    clearHighlight(node.uuid);
                }}
            >
                <div className={clsx('context-tree-node', { 'selected-node': isSelected })}>
                    <b className="node-title">Delivery </b>
                    <br />
                    {(delivery.raw?.location as GeckoRawDeliveryLocationBcos).type === 'bcos' && (
                        <a onClick={handleOpenUrl}>BCOS &#10697;</a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default DeliveryNode;
