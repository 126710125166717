export type AlertOptionType = {
    id: string;
    name: string;
    description: string;
};

export enum AlertNotificationRangeUnitType {
    TIMES = 'times',
    RUNS = 'runs',
    MINUTES = 'minutes',
    HOURS = 'hours',
    DAYS = 'days',
}

export type AlertNotificationRangeType = {
    length: number | undefined;
    unit: AlertNotificationRangeUnitType;
};

export type AlertNotificationMsgRecipientType = {
    uuid: number;
    name?: string;
    firm?: string;
};

export type AlertNotificationMsgSettings = {
    enabled: boolean;
    data: {
        frequency: AlertNotificationRangeType;
        recipients: AlertNotificationMsgRecipientType[];
    };
};

export type AlertNotificationOccurrenceType = {
    numOccurrences: number | undefined;
    observationWindow: {
        enabled: boolean;
        data: AlertNotificationRangeType;
    };
};

export type AlertNotificationType = {
    createWorkItem: boolean;
    sendMsgSettings: AlertNotificationMsgSettings;
};
