import React, { useContext } from 'react';
import clsx from 'clsx';
import { formatByteSize, useSelectedNode, SelectionApiContext, LinkHandlerContext } from './hooks';
import { CTDatum } from './runTreeTypes';
import { GeckoContextTree } from '@webacq/wa-shared-definitions';
import { Tooltip } from '@mui/material';

export type NavNodeProps = {
    node: CTDatum;
};

const NavNode = ({ node }: NavNodeProps): JSX.Element => {
    const contextTree = node.body as GeckoContextTree;
    const { onOpenUrl } = useContext(LinkHandlerContext);
    let duration = -1;
    if (Array.isArray(contextTree?.timeline)) {
        const timeline = contextTree.timeline.find((t) => t.name === contextTree?.navigationNodeId);
        duration = timeline?.duration ?? -1;
    }
    const selection = useSelectedNode();
    const isHighlighted = selection?.highlightedNodes.includes(node.uuid);

    const isSelected = selection?.selectedNode?.uuid === node.uuid;
    const { onSelectNode, onHighlightNode, clearHighlight } = useContext(SelectionApiContext);
    const errorMsg = contextTree.errors?.length && contextTree.errors[0].errorMsg;

    const url =
        contextTree.url && contextTree.url.length > 50 ? contextTree.url?.slice(0, 50) + '...' : contextTree.url;

    const handleOpenUrl = (): void => {
        if (contextTree.url) {
            onOpenUrl(contextTree.url);
        }
    };

    return (
        <div className="context-tree-node-wrapper">
            <div
                className={clsx('context-tree-node-base', 'nav-node', {
                    'selected-node': isSelected,
                    'highlighted-node': isHighlighted,
                    'error-node': errorMsg,
                })}
                onClick={(e) => {
                    onSelectNode(node);
                    e.stopPropagation();
                }}
                onMouseEnter={() => {
                    onHighlightNode('uuid', node.uuid);
                }}
                onMouseLeave={() => {
                    clearHighlight(node.uuid);
                }}
            >
                <div className={clsx('context-tree-node', { 'selected-node': isSelected })}>
                    <span className="node-title">
                        <b>Navigation</b>
                        {errorMsg && (
                            <Tooltip title={`Error: ${errorMsg}`} placement="top">
                                <span>&nbsp;&#x26A0;</span>
                            </Tooltip>
                        )}
                    </span>
                    <br />
                    <a
                        style={{ textAlign: 'center' }}
                        onClick={handleOpenUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {url}
                    </a>
                    <br />
                    <b>Size:</b> <span>{formatByteSize(contextTree?.contentLength ?? 0)} </span>
                    {duration !== -1 && (
                        <>
                            <b> &nbsp;Duration: </b>
                            <span>{duration}ms</span>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NavNode;
