import React, { useContext } from 'react';
import clsx from 'clsx';
import { useSelectedNode, SelectionApiContext } from './hooks';
import { CTDatum } from './runTreeTypes';

export type StartingNodeProps = {
    node: CTDatum;
};

const StartingNode = ({ node }: StartingNodeProps): JSX.Element => {
    const selection = useSelectedNode();

    const isSelected = selection?.selectedNode?.uuid === node.uuid;
    const { onSelectNode } = useContext(SelectionApiContext);

    return (
        <div className="context-tree-node-wrapper">
            <div
                className={clsx('context-tree-node-base', {
                    'selected-node': isSelected,
                })}
                onClick={(e) => {
                    onSelectNode(node);
                    e.stopPropagation();
                }}
            >
                <div className="context-tree-node">
                    <span className="node-title">
                        <b>Job Start</b>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default StartingNode;
