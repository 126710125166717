import React from 'react';

type LabelProps = {
    name: string | React.ReactElement;
    description?: string | React.ReactElement;
};

const ViewerLabel = ({ name, description }: LabelProps): React.ReactElement => {
    return (
        <div className="data-viewer-label">
            <b>{name} </b>
            <small> {description}</small>
        </div>
    );
};

export default ViewerLabel;
