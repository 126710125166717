import React, { useMemo, useState, useEffect, useRef } from 'react';
import { IProvenanceRecord, IProvenanceNode } from './runTreeTypes';
import { Tree, TreeNode } from 'react-organizational-chart';
import { createProvenanceTree } from './hooks';
import ProvenanceNode from './ProvenanceNode';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchRef, useControls } from 'react-zoom-pan-pinch';

type ProvenanceTreeProps = {
    records: IProvenanceRecord[];
    rootStepDeliveryUuid?: string;
    onRefresh: () => void;
};


export default function ProvenanceTree({
    records,
    rootStepDeliveryUuid,
    onRefresh,
}: ProvenanceTreeProps): JSX.Element {
    const [hideNonCrawl, setHideNonCrawl] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [treeWidth, setTreeWidth] = useState<string>('200px');
    const transformRef = useRef<ReactZoomPanPinchRef>(null);
    useEffect(() => {
        if (wrapperRef?.current) {
            const treeElem = wrapperRef.current.querySelector('ul');
            if (treeElem) {
                const style = window.getComputedStyle(treeElem);
                setTreeWidth(style.width);
            }
        }
    }, [wrapperRef?.current, treeWidth]);

    const root = useMemo(() => createProvenanceTree(records, hideNonCrawl), [records, hideNonCrawl]);

    useEffect(() => {
        if (transformRef.current && rootStepDeliveryUuid && root) {
            const node = root.children.find((child) => child.uuid.includes(rootStepDeliveryUuid));
            if (node) {
                transformRef.current.zoomToElement(node.uuid, 1);
            }
        }
    }, [rootStepDeliveryUuid, transformRef, root]);


    function createTreeNode(node: IProvenanceNode | undefined, isActive: boolean): React.ReactElement {
        if (!node) return <></>;
        const label = <ProvenanceNode node={node} isActive={isActive} />;
        return (
            <TreeNode label={label} key={node.uuid}>
                {node?.children.map((child) => createTreeNode(child, isActive))}
            </TreeNode>
        );
    }
    const Controls = () => {
        const { zoomIn, zoomOut, resetTransform } = useControls();
        return (
            <>
                <label className="provenance-checkbox">
                    <input type="checkbox" checked={hideNonCrawl} onChange={event => {
                        setHideNonCrawl(event.target.checked);
                        resetTransform();
                    }}/>
                    <span>&nbsp;Hide non-crawl workflows</span>
                </label>
                <div className="provenance-zoom-controls">
                    <button title="zoom in" onClick={() => zoomIn()}>＋</button>
                    <button title="zoom out" onClick={() => zoomOut()}>−</button>
                    <button title="reset zoom" onClick={() => resetTransform()}>Reset Zoom</button>
                    <button title="refresh provenance records" onClick={onRefresh}>⟳ Refresh Records</button>
                </div>
            </>
        );
    };

    if (!root) return <></>;

    return (
        <div
            className="provenance-tree-viewer run-tree-container"
            ref={wrapperRef}
            style={{
                width: '100%',
                height: '100%',
                position: 'relative'
            }}>

            <TransformWrapper
                minScale={0.25}
                maxScale={1.25}
                centerOnInit
                limitToBounds={false}
                zoomAnimation={{ disabled: true }}
                ref={transformRef}
            >
                 <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="vertical-tree-label">
                        Provenance
                    </div>
                    <div className="provenance-controls-panel ">
                        <Controls/>
                    </div>
                </div>

                <TransformComponent
                    wrapperStyle={{ height: 'calc(100% - 21px)', width: '100%' }}
                    contentStyle={{ height: '100%', width: treeWidth }}
                >
                    <Tree
                        lineWidth="1px"
                        lineColor="rgb(1, 219, 208)"
                        lineBorderRadius="4px"
                        label={<ProvenanceNode node={root} isActive />}
                    >
                        {root.children.map((child) =>
                            createTreeNode(child, !rootStepDeliveryUuid || child.uuid.includes(rootStepDeliveryUuid)),
                        )}
                    </Tree>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
}
