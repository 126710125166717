/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import { Box, Checkbox, FormControlLabel, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import RangeSelector from './RangeSelector';
import { AlertNotificationOccurrenceType, AlertNotificationRangeUnitType } from '../types';

const useStyles = makeStyles((theme: Theme) => ({
    spacing: {
        marginLeft: theme.spacing(2),
    },
    frequencyBox: {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    observationWindowBox: {
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    consecutively: {
        marginTop: theme.spacing(1),
    },
    notificationType: {
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    observationWindow: {
        marginLeft: 2,
    },
    notificationOptions: {
        marginLeft: theme.spacing(4),
        // paddingBottom: theme.spacing(2),
    },
    wiOptionBox: {
        padding: theme.spacing(1),
    },
}));

interface OccurrencePropsType {
    occurrenceConfig: AlertNotificationOccurrenceType;
    onChange: (occurrenceConfig: AlertNotificationOccurrenceType) => void;
}

const Occurrence = (props: OccurrencePropsType): JSX.Element => {
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="flex-start">
            <Box display="flex" alignItems="center" className={classes.frequencyBox}>
                <Typography>Create if occurs</Typography>

                <Box className={classes.spacing}>
                    <RangeSelector
                        unitsToOmit={[
                            AlertNotificationRangeUnitType.RUNS,
                            AlertNotificationRangeUnitType.DAYS,
                            AlertNotificationRangeUnitType.HOURS,
                            AlertNotificationRangeUnitType.MINUTES,
                        ]}
                        range={{
                            length: props.occurrenceConfig.numOccurrences,
                            unit: AlertNotificationRangeUnitType.TIMES,
                        }}
                        onChange={(range) => {
                            const newOccurrenceConfig = {
                                ...props.occurrenceConfig,
                                numOccurrences: range.length,
                            };
                            props.onChange(newOccurrenceConfig);
                        }}
                    />
                </Box>
            </Box>

            <Box className={classes.observationWindowBox}>
                <FormControlLabel
                    className={classes.consecutively}
                    control={
                        <Checkbox
                            checked={!props.occurrenceConfig.observationWindow.enabled}
                            onChange={(event, checked) => {
                                const newOccurrenceConfig = { ...props.occurrenceConfig };
                                newOccurrenceConfig.observationWindow.enabled = !checked;
                                props.onChange(newOccurrenceConfig);
                            }}
                        />
                    }
                    label="consecutively"
                />

                {props.occurrenceConfig.observationWindow.enabled && (
                    <Box display="flex" alignItems="center" className={classes.observationWindow}>
                        <Typography>in</Typography>

                        <Box className={classes.spacing}>
                            <RangeSelector
                                unitsToOmit={[AlertNotificationRangeUnitType.TIMES]}
                                range={props.occurrenceConfig.observationWindow.data}
                                onChange={(range) => {
                                    const newOccurrenceConfig = {
                                        ...props.occurrenceConfig,
                                    };
                                    newOccurrenceConfig.observationWindow.data = range;
                                    props.onChange(newOccurrenceConfig);
                                }}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default Occurrence;
